import styled from "styled-components";
import Initial from "../modules/Initial";
import { StyledSectionTemplate } from "../templates/StyledSectionTemplate";

const StyledInitialSection = styled(StyledSectionTemplate)`
  display: none;

  .off & {
    display: flex;
    padding-top: 30px;
  }
`;

const InitialSection = () => {
  return (
    <StyledInitialSection>
      <Initial />
    </StyledInitialSection>
  );
};

export default InitialSection;
