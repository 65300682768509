export default {
  views: {
    initial: {
      hello: "Welcome to the professional website of {{name}}",
      question: "Would you like to boost your team?",
    },
    welcome: {
      hello: "Hi, I'am",
    },
    about: {
      title: "About me",
      content: {
        one: {
          title: "Currently",
          text: {
            p1: "Since March 2021, I have been working full-time as a front-end developer at (formerly Blue Media SA), a company providing solutions for electronic transactions.",
            p2: "In addition, I am a member of the <0>HackerSpace Tricity</0> community and I am involved in non-professional IT projects. In June 2022, during the 2nd edition of the Hack4Change technological and ecological hackathon in Gdańsk, our team received the 2nd prize for the application design supporting children with challenges (<0>Token</0>).",
            p3: "I also conducted frontend courses, including as part of HackerSpace Tricity and for InfoShare Academy. I took part in the 255th and 256th <0>Programmer Week</0>, where I conducted workshops in JS and modern styling. Four times, as part of the <1>Meet and Code</1> project, I conducted frontend workshops on the basics of SASS/SCSS, Tailwind and digital accessibility.",
            p4: "and I am actively involved as a frontend developer in the Gdańsk accessibility forum: A11y - Accessibility - Dostępność. I am constantly trying to improve my qualifications in the area of digital accessibility. In 2021, I completed a training on digital accessibility conducted by the <1>Widzialni Foundation</1>. In May 2023, I participated in workshops on accessibility in the <2>Gdańsk Gallery of the Senses</2>.",
          },
          images: {
            fig1: "Meeting of the 1th accessibility forum in Gdańsk (Author: Marcin Klinikowski)",
            fig2: "Meeting of the 2nd accessibility forum in Gdańsk (Author: Marcin Klinikowski)",
            fig3: "My presentation at the 2nd edition of accessibility forum in Gdansk (Author: Marcin Klinikowski)",
            fig4: "Certificat of the digital accessibility course",
            fig5: "Prize giving on Hack4change",
            fig6: "Workshops as part of the Meet and Code project",
            alt1: "A group of people posing together for a photo.",
            alt2: "A group of people posing together for a photo.",
            alt3: "A man presenting slides.",
            alt4: "Graphics showing the certificate of completion of the accessibility course.",
            alt5: "A group of people posing together for a photo.",
            alt6: "Several people sitting at a table with computers, the figure of the presenter in the background.",
          },
        },
        two: {
          title: "Start in IT",
          text: {
            p1: "I started to change my career as a frontend developer in 2018. Initially, I learned the basics of frontend on my own and from online courses.",
            p2: "In 2019 I completed a 45-hour 'UX designer' course of InfoShare Academy, led by Karolina Troka (PGS Software S.A.), which allowed me to carry out this process better.",
            p3: "In March 2020, I completed a 72-hour course 'JavaScript + React from scratch' (Code:me Foundation), led by Marcin Kałużny (Wirtualna Polska). In 2020, I also participated in the UX design workshops for the 'Żeton' application project, led by Weronik Spaleniak (Digital Toucan: Panorama & JQL Search Extensions for Jira).",
            p4: "In the years 2019-2021, as a freelance frontend developer, I made websites and application projects, including in cooperation with the <0>Between-Pomiędzy Foundation</0>.",
          },
          images: {
            fig1: "UX Design course certificate",
            alt1: "Graphics showing the certificate of completion of the UX Desinger course.",
            fig2: "Completion of the UX designe course",
            alt2: "A group of people holding course completion diplomas.",
            fig3: "JavaScript with React course certificate",
            alt3: "Graphics showing the certificate of completion of the JavaScript + React from scratch course.",
            fig4: "Code:me Foundation programming training",
            alt4: "A group of programmers with laptops.",
          },
        },
        three: {
          title: "Before IT",
          text: {
            p1: "Even before the decision to change my profession, I had some experience in non-professional web design based on CMS and digital graphics: book and magazine covers, graphics, logos, icons.",
            p2: "I have extensive work experience in science and education. I worked as an assistant professor at the <0>Pomeranian University in Słupsk</0> for 11 years.",
          },
        },
      },
    },
    stack: {
      title: "My stack",
      subtitles: {
        front: "Frontend technologies",
        back: "Backend technologies",
        design: "Design skills",
        test: "Test tools",
        dev: "Developer tools",
      },
    },
    resume: {
      title: "Resume",
      labels: {
        english: "English",
        polish: "Polish",
        subtitle: "take my resume OR visit my profiles",
      },
      buttons: {
        openPdf: "Open the document pdf in the new window",
      },
    },
    contact: {
      title: "Contact",
      labels: {
        email: "Your email",
        message: "Message",
      },
      buttons: {
        submit: "Submit",
      },
      messages: {
        success: "Success!",
        succesText: "The message has sent.",
        failed: "Error!",
        failedText: "Sending the message failed. Try again.",
        fieldRequired: "Field is required!",
        emailInvalid: "Enter valid email address!",
      },
    },
  },
  common: {
    on: "Yes",
    off: "Off",
    author: "Paweł Nieczuja-Ostrowski",
    profession: "Frontend Developer",
    start: "Start",
    about: "About me",
    stack: "Stack",
    resume: "Resume",
    contact: "Contact",
    see: "see",
    a11y: {
      handlePower: "Turn on page view",
      handleCockpit: {
        on: "Show main menu",
        off: "Hide main menu",
      },
      handleSettings: {
        on: "Expand settings menu",
        off: "Collapse settings menu",
      },
      handleTheme: {
        dark: "Turn on dark color scheme",
        light: "Turn on light color scheme",
      },
      handleLanguage: {
        en: "Turn on the English language version of the website",
        pl: "Turn on the Polish language version of the website",
      },
      handleContrast: {
        on: "Turn on high contrast on the page",
        off: "Turn off high contrast on the page",
      },
      contact: "Go to contact section",
      submit: "Submit message",
      submitReaction: "Confirm and return to form",
      start: "Go to start section",
      about: "Go to the section presenting the author's profile",
      stack: "Go to the section presenting the author's skills ",
      resume: "Go to the section containing the author's resume",
      openBlank: "Open in a new window and go to the {{ name }} website",
    },
  },
};
