import React from "react";
import ImageFigure from "../../elems/ImageFigure";
import ParagraphLink from "../../elems/ParagraphLink";
import SectionParagraph from "../../elems/SectionParagraph";
import SectionSubtitle from "../../elems/SectionSubtitle";
import { StyledImagesTemplate } from "../../templates/StyledImagesTemplate";
import { useTranslation, Trans } from "react-i18next";

const AboutContentTwo = () => {
  const { t } = useTranslation();
  return (
    <>
      <SectionSubtitle>{t("views.about.content.two.title")}</SectionSubtitle>
      <SectionParagraph>
        {t("views.about.content.two.text.p1")}
      </SectionParagraph>
      <SectionParagraph>
        {t("views.about.content.two.text.p2")}
      </SectionParagraph>

      <SectionParagraph>
        {t("views.about.content.two.text.p3")}
      </SectionParagraph>

      <SectionParagraph>
        <Trans i18nKey="views.about.content.two.text.p4">
          <ParagraphLink
            link="https://between.org.pl/"
            title={t("common.a11y.openBlank", {
              name: "Between-Pomiędzy Foundation",
            })}
            target="_blank"
          ></ParagraphLink>
        </Trans>
      </SectionParagraph>

      <StyledImagesTemplate>
        <ImageFigure
          src="./images/ia-certificate.jpg"
          alt={t("views.about.content.two.images.alt1")}
          caption={t("views.about.content.two.images.fig1")}
        />
        <ImageFigure
          src="./images/ux-course.jpg"
          alt={t("views.about.content.two.images.alt2")}
          caption={t("views.about.content.two.images.fig2")}
        />
        <ImageFigure
          src="./images/codeme-certificat.jpg"
          alt={t("views.about.content.two.images.alt3")}
          caption={t("views.about.content.two.images.fig3")}
        />
        <ImageFigure
          src="./images/zeton-kurs-01.jpg"
          alt={t("views.about.content.two.images.alt4")}
          caption={t("views.about.content.two.images.fig4")}
        />
      </StyledImagesTemplate>
    </>
  );
};

export default AboutContentTwo;
