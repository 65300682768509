import styled from "styled-components";

export const StyledImagesTemplate = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;
