import React from "react";
import ImageFigure from "../../elems/ImageFigure";
import ParagraphLink from "../../elems/ParagraphLink";
import SectionParagraph from "../../elems/SectionParagraph";
import SectionSubtitle from "../../elems/SectionSubtitle";
import { StyledImagesTemplate } from "../../templates/StyledImagesTemplate";
import { useTranslation, Trans } from "react-i18next";

const AboutContentOne = () => {
  const { t } = useTranslation();
  return (
    <>
      <SectionSubtitle>{t("views.about.content.one.title")}</SectionSubtitle>
      <SectionParagraph>
        <Trans i18nKey="views.about.content.one.text.p1">
          <ParagraphLink
            link="https://autopay.pl/"
            title={t("common.a11y.openBlank", { name: "Autopay SA" })}
            target="_blank"
          >
            {t("common.see")}
          </ParagraphLink>
        </Trans>
      </SectionParagraph>

      <SectionParagraph>
        <Trans i18nKey="views.about.content.one.text.p2">
          <ParagraphLink
            link="https://hs3.pl/"
            title={t("common.a11y.openBlank", { name: "HackerSpace Tricity" })}
            target="_blank"
          ></ParagraphLink>
          <ParagraphLink
            link="https://widzialni.org/"
            title={t("common.a11y.openBlank", { name: "Widzialni Foundation" })}
            target="_blank"
          ></ParagraphLink>
          <ParagraphLink
            link="https://gdanskagaleriazmyslow.pl/"
            title={t("common.a11y.openBlank", {
              name: "Gdańska Galeria Zmysłów",
            })}
            target="_blank"
          ></ParagraphLink>
        </Trans>
      </SectionParagraph>

      <SectionParagraph>
        <Trans i18nKey="views.about.content.one.text.p3">
          <ParagraphLink
            link="(https://tydzienprogramisty.pl/"
            title={t("common.a11y.openBlank", { name: "Tydzien programisty" })}
            target="_blank"
          ></ParagraphLink>
          <ParagraphLink
            link="(https://meet-and-code.org/pl/pl/"
            title={t("common.a11y.openBlank", { name: "Meet and Code" })}
            target="_blank"
          ></ParagraphLink>
        </Trans>
      </SectionParagraph>

      <SectionParagraph>
        <Trans i18nKey="views.about.content.one.text.p4">
          <ParagraphLink
            link="(https://www.projekt-zeton.pl/"
            title={t("common.a11y.openBlank", { name: "Żeton project" })}
            target="_blank"
          ></ParagraphLink>
        </Trans>
      </SectionParagraph>

      <StyledImagesTemplate>
        <ImageFigure
          src="./images/obj-1ed-01.jpg"
          alt={t("views.about.content.one.images.alt1")}
          caption={t("views.about.content.one.images.fig1")}
        />
        <ImageFigure
          src="./images/obj-012.jpg"
          alt={t("views.about.content.one.images.alt2")}
          caption={t("views.about.content.one.images.fig2")}
        />
        <ImageFigure
          src="./images/obj-066b.jpg"
          alt={t("views.about.content.one.images.alt3")}
          caption={t("views.about.content.one.images.fig3")}
        />
        <ImageFigure
          src="./images/hackaton-01.jpg"
          alt={t("views.about.content.one.images.alt5")}
          caption={t("views.about.content.one.images.fig5")}
        />
        <ImageFigure
          src="./images/meet-n-code-course.jpg"
          alt={t("views.about.content.one.images.alt6")}
          caption={t("views.about.content.one.images.fig6")}
        />
        <ImageFigure
          src="./images/wcag-certyficate.jpg"
          alt={t("views.about.content.one.images.alt4")}
          caption={t("views.about.content.one.images.fig4")}
        />
      </StyledImagesTemplate>
    </>
  );
};

export default AboutContentOne;
