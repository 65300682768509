export const StyledGlassTemplate = `
// background: rgba( 255, 255, 255, 0.05 );
// box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
// backdrop-filter: blur( 8px );
// -webkit-backdrop-filter: blur( 8px );
// border-radius: 10px;
// border: 1px solid rgba( 255, 255, 255, 0.18 );

backdrop-filter: blur( 10px );
-webkit-backdrop-filter: blur( 10px );
// border-radius: 10px;
// border: 1px solid var(--color-title-light);

//   .dark-theme & {
//     border: 1px solid var(--color-title-dark);
//   }
`;
