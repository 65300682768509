import styled from "styled-components";

export const StyledButtonsLRWrapper = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 300px; */
`;

export const StyledButtonLR = styled.button`
  cursor: pointer;
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-color: transparent;
  border: none;
  /* width: 40px;
  height: 100px; */
  padding: 0;
  letter-spacing: -10px;
  font-size: 1.5rem;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 900;
  transition: 0.2s;

  @media (min-width: 992px) {
    letter-spacing: -14px;
    font-size: 2rem;
  }

  > span {
    writing-mode: vertical-rl;
    text-orientation: upright;
  }

  &:not([disabled]) {
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--color-primary-light);
    /* color: var(--color-secondary-light);  */

    .dark-theme & {
      color: transparent;
      -webkit-text-stroke-color: var(--color-primary-dark);
      /* color: var(--color-secondary-dark); */
    }

    &:hover {
      color: var(--color-primary-light);

      .dark-theme & {
        color: var(--color-primary-dark);
      }
    }
  }

  &[disabled] {
    color: transparent;
    /* -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--color-secondary-light); */

    .dark-theme & {
      color: transparent;
      /* -webkit-text-stroke-color: var(--color-secondary-dark-3); */
    }
  }
`;
