import styled from "styled-components";
import Navs from "../modules/Navs";
import { StyledGlassTemplate } from "../templates/StyledGlassTemplate";

const StyledBottomBar = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 99;
  bottom: -220px;
  transition: 1s;
  width: max-content;
  padding: 20px;
  /* ${StyledGlassTemplate} */

  .off & {
    display: none;
  }

  .cockpit & {
    bottom: 20px;
  }
`;

const AppBottomBar = () => {
  return (
    <StyledBottomBar>
      <Navs />
    </StyledBottomBar>
  );
};

export default AppBottomBar;
