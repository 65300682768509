import { useContext, useState } from "react";
import styled from "styled-components";
import ButtonHex from "../elems/ButtonHex";
// import { PowerContext, globalPower } from "../context/power-context";
// import { SectionContext, globalSection } from "../context/section-context";
import {
  ThemeContext,
  globalTheme,
  ContrastContext,
  globalContrast,
} from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import { languageSwitch } from "../mixins/lang";
import { useTranslation } from "react-i18next";

const StyledHeader = styled.header`
  position: fixed;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 20px);
  left: 50%;
  transform: translateX(-50%);
  /* top: -73px; */
  top: 10px;
  transition: 1s;
  height: 80px;

  .settings {
    position: relative;

    > div {
      display: none;
    }

    > #settings-button {
      display: flex;
    }

    &.open {
      > div {
        display: flex;
      }

      > div:nth-of-type(2),
      div:nth-of-type(3),
      div:nth-of-type(4),
      div:nth-of-type(5) {
        position: aboslute;
        animation: openSettings 0.2s forwards ease-in;
      }

      > div:nth-of-type(2) {
        top: -22px;
        right: 48px;
      }
      > div:nth-of-type(3) {
        opacity: 0;
        top: -96px;
        right: calc(48px * 2);
        animation-delay: 0.2s;
      }
      > div:nth-of-type(4) {
        opacity: 0;
        top: -92px;
        right: calc(48px * 2);
        animation-delay: 0.4s;
      }
      > div:nth-of-type(5) {
        opacity: 0;
        top: -166px;
        right: calc(48px * 3);
        animation-delay: 0.6s;
      }

      @media (min-width: 375px) {
        > div:nth-of-type(4) {
          opacity: 0;
          top: -118px;
          right: calc(48px * 3);
          animation-delay: 0.4s;
        }
        > div:nth-of-type(5) {
          opacity: 0;
          top: -192px;
          right: calc(48px * 4);
          animation-delay: 0.6s;
        }
      }

      @keyframes openSettings {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
  }

  .theme-mark {
    font-size: 1.5rem;
    line-height: 0.9;
  }

  .off & {
    top: 10px;

    > .powerButtonOff {
      display: none;
    }
  }

  /* @media (min-width: 992px) {

    top: 10px;
  } */

  .cockpit & {
    top: 10px;

    /* @media (min-width: 992px) {
      top: 10px;
    } */
  }
`;

const StyledContainerMiddle = styled.div`
  min-width: 150px;
`;

const AppHeader = (props) => {
  // const power = useContext(PowerContext);
  // const section = useContext(SectionContext);
  // const theme = useContext(ThemeContext);
  // const contrast = useContext(ContrastContext);
  const { isTheme, setTheme } = useContext(ThemeContext);
  const { isContrast, setContrast } = useContext(ContrastContext);
  const [isLanguage, setLanguage] = useState(document.documentElement.lang);
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  // const handlePower = () => {
  //   power.setPower(power.isPower ? globalPower.on : globalPower.off);
  //   section.setSection(globalSection.welcome);
  // };

  const handleLanguage = (lang) => {
    if (lang !== isLanguage) {
      languageSwitch(lang);
      setLanguage(lang);
    }
  };

  const handleContrast = () => {
    setContrast(isContrast ? globalContrast.off : globalContrast.on);
  };

  const handleTheme = () => {
    setTheme(
      isTheme === globalTheme.light ? globalTheme.dark : globalTheme.light
    );
    $tc.setThemeLocal(
      isTheme === globalTheme.light ? globalTheme.dark : globalTheme.light
    );
  };

  const handleSettings = () => {
    setOpen(!isOpen);
    // console.log("hej", isOpen, isTheme, isContrast);
  };

  return (
    <StyledHeader theme={$tc.setContrastTheme(isContrast, isTheme)}>
      {/* <ButtonHex small active={true} action={handlePower} name="powerButtonOff">
        <span>{power.isPower ? t("common.on") : t("common.off")}</span>
      </ButtonHex> */}
      <StyledContainerMiddle></StyledContainerMiddle>
      <div className={isOpen ? "settings open" : "settings"}>
        <ButtonHex
          small
          action={handleSettings}
          id="settings-button"
          title={
            isOpen
              ? t("common.a11y.handleSettings.off")
              : t("common.a11y.handleSettings.on")
          }
        >
          <span className="theme-mark-icon icon-dots"></span>
        </ButtonHex>

        <ButtonHex
          small
          action={handleTheme}
          title={
            isTheme === globalTheme.dark
              ? t("common.a11y.handleTheme.light")
              : t("common.a11y.handleTheme.dark")
          }
        >
          <span className="theme-mark-icon icon-moon-and-sun"></span>
        </ButtonHex>
        <ButtonHex
          small
          action={() => handleLanguage("en")}
          active={isLanguage === "en"}
          disabled={isLanguage === "en"}
          title={t("common.a11y.handleLanguage.pl")}
        >
          EN
        </ButtonHex>
        <ButtonHex
          small
          action={() => handleLanguage("pl")}
          active={isLanguage === "pl"}
          disabled={isLanguage === "pl"}
          title={t("common.a11y.handleLanguage.pl")}
        >
          PL
        </ButtonHex>
        <ButtonHex
          small
          action={() => handleContrast()}
          active={isContrast}
          title={
            isContrast
              ? t("common.a11y.handleContrast.off")
              : t("common.a11y.handleContrast.on")
          }
        >
          <span className="theme-mark-icon icon-contrast"></span>
        </ButtonHex>
      </div>
    </StyledHeader>
  );
};

export default AppHeader;
