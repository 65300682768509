import i18n from "../i18n";

export const languageSwitch = (newLang) => {
  const i18settedLang = localStorage.getItem("i18nextLng");
  // console.log(i18settedLang);
  if (!newLang) {
    document.documentElement.lang =
      i18settedLang.charAt(0) + i18settedLang.charAt(1);
    return;
  }
  if (newLang && document.documentElement.lang !== newLang) {
    i18n.changeLanguage(newLang);
    document.documentElement.lang = newLang;
    return;
  }
};
