import React, { createContext } from "react";

export const globalPower = {
  on: false,
  off: "off",
};

export const PowerContext = createContext({
  isPower: globalPower.on,
  setPower: (power) => {},
});

// export default PowerContext;
