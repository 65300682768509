import { useContext } from "react";
import styled from "styled-components";
import PageTitle from "../elems/PageTitle";
import PageSubtitle from "../elems/PageSubtitle";
import { SectionContext, globalSection } from "../context/section-context";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import FormButton from "../elems/FormButton";
import { useTranslation } from "react-i18next";

const StyledHero = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;

  .off & {
    display: none;
  }

  > button {
    margin: 20px auto 0;
    font-size: 1.1rem;
    padding: 8px 10px;
    border: solid 2px ${(props) => props.theme.colorSecondary};
  }
  > button:first-letter {
    font-size: 1.2rem;
  }
`;

const Hero = () => {
  const { t } = useTranslation();
  const section = useContext(SectionContext);
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);

  const handleSection = () => {
    section.setSection(globalSection.contact);
  };
  return (
    <StyledHero theme={$tc.setContrastTheme(isContrast, isTheme)}>
      <PageSubtitle>{t("views.welcome.hello")}</PageSubtitle>
      <PageTitle>{t("common.author")}</PageTitle>
      <PageSubtitle>{t("common.profession")}</PageSubtitle>
      <FormButton
        action={handleSection}
        icon="&#9993;"
        text={t("common.contact")}
        title={t("common.a11y.contact")}
      />
    </StyledHero>
  );
};

export default Hero;
