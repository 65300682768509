import styled from "styled-components";

const StyledLeftBar = styled.div`
  display: none;
  position: fixed;
  width: 100px;
  height: calc(100vh - 70px - 160px);
  padding-top: 70px;
  padding-bottom: 160px;

  @media (min-width: 992px) {
    display: block;
  }

  .off & {
    display: none;
  }
`;

const AppLeftBar = () => {
  return <StyledLeftBar></StyledLeftBar>;
};

export default AppLeftBar;
