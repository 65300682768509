import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DecoratorsClass } from "../class/decorators.class";

const StyledLightDecortor = styled.svg`
  position: absolute;
  background-color: transparent;
  stroke-width: 0;
  transition: 0.2s;
  /* top: ${(props) => (props.posTop ? props.posTop : "auto")}; */
  left: ${(props) => (props.posLeft ? props.posLeft : "auto")};
  right: ${(props) => (props.posRight ? props.posRight : "auto")};
  fill: var(--color-primary-light);
  fill-opacity: ${(props) => (props.opacity ? props.opacity : "0.3")};
  stroke: var(--color-tertiary-light);
  filter: blur(1px);
  /* background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%); */
  .dark-theme & {
    fill: var(--color-primary-dark);
    fill-opacity: ${(props) => (props.opacity ? props.opacity : "0.3")};
    stroke: var(--color-tertiary-dark);
    filter: blur(2px);
  }

  .off & {
    display: none;
  }

  @keyframes decoratorMove {
  }
`;

const LightDecortor = ({ index }) => {
  const [lightParams] = useState(DecoratorsClass.setObjectToLightElem());
  const [posTop, setPosTop] = useState(DecoratorsClass.renderPosTop());
  const [posLeft, setPosLeft] = useState(DecoratorsClass.renderPosLeft());
  const [step, setStep] = useState(0);
  const [dirVertical, setDirVerical] = useState(
    index % 2 === 0 ? "top" : "down"
  );
  const [dirHorizontal, setDirHorizontal] = useState(
    index % 2 === 0 ? "left" : "right"
  );
  const [speedVertical, setSpeedVertical] = useState(
    Math.ceil(Math.random() * 10) / 20
  );
  const [speedHorizontal, setSpeedHorizontal] = useState(
    Math.floor(Math.random() * 10) / 20
  );

  const setPositionVertical = (pos) => {
    let newPos =
      dirVertical === "top" ? pos - speedVertical : pos + speedVertical;
    setPosTop(newPos);
  };

  const setPositionHorizontal = (pos) => {
    let newPos =
      dirHorizontal === "left" ? pos - speedHorizontal : pos + speedHorizontal;
    setPosLeft(newPos);
  };

  const changePosVertical = () => {
    setStep(step + 1);
    if (posTop > -10 && posTop < 110) {
      setPositionVertical(posTop);
    }

    if (posTop >= 110) {
      setDirVerical("top");
      setPositionVertical(posTop);
    }

    if (posTop <= -10) {
      setDirVerical("down");
      setPositionVertical(posTop);
    }
  };

  const changePosHorizontal = () => {
    setStep(step + 1);
    if (posLeft > -10 && posLeft < 110) {
      setPositionHorizontal(posLeft);
    }

    if (posLeft >= 110) {
      setDirHorizontal("left");
      setPositionHorizontal(posLeft);
    }

    if (posLeft <= -10) {
      setDirHorizontal("right");
      setPositionHorizontal(posLeft);
    }
  };

  useEffect(() => {
    const time = setTimeout(() => {
      changePosVertical();
      changePosHorizontal();
    }, 60);

    return () => {
      clearTimeout(time);
    };
  }, [step]);

  return (
    <StyledLightDecortor
      xmlns="http://www.w3.org/2000/svg"
      width={"50"}
      height={"50"}
      posLeft={lightParams.posLeft + "%"}
      // posTop={lightParams.posTop + "%"}
      posRight={lightParams.posRight + "%"}
      opacity={lightParams.opacity}
      style={{ top: posTop + "%", left: posLeft + "%" }}
    >
      <circle
        cx="25"
        cy="25"
        r={lightParams.radius ? lightParams.radius : "20"}
      />
    </StyledLightDecortor>
  );
};

export default LightDecortor;
