import { useState, useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import { send } from "emailjs-com";
import styled from "styled-components";
import InputElem from "../elems/InputElem";
import FormButton from "../elems/FormButton";
import SpinnerHex from "./SpinnerHex";
import { useTranslation } from "react-i18next";

const StyledContactForm = styled.div`
  /* max-width: 600px; */
  margin: 0 auto;
  min-width: 250px;
  z-index: 1;

  @media (min-width: 600px) {
    width: 400px;
  }

  @media (min-width: 768px) {
    width: 450px;
  }

  @media (min-width: 992px) {
    /* min-width: 300px; */
    width: 500px;
  }

  > form {
    margin: 0;
  }
`;

const StyledSendingEffectContainer = styled.div`
  display: flex;
  justify-self: center;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  /* min-width: 300px; */

  @media (min-width: 992px) {
    /* min-width: 300px; */
    /* width: 300px; */
  }

  padding: 10px;
  height: 100%;

  > p {
    margin-top: 20%;
    color: ${(props) => props.theme.colorTitle};
    text-align: center;
  }

  > button {
    margin: 40px auto 60px;
  }
`;

const ContactForm = () => {
  const { t } = useTranslation();
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);
  const [isProcessing, setProcessing] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const [emailData, setEmailData] = useState(null);
  const [messageData, setMessageData] = useState(null);
  const [isInputEmpty, setInputEmpty] = useState(false);
  const [isValid, setValid] = useState(true);

  const data = {
    service_id: "service_4lmmmod",
    template_id: "portfolitemplate_64nfvwd",
    message_data: {
      from_name: "Paweł Nieczuja Website",
      to_name: "Paweł",
      message: "",
      reply_to: "",
    },
    user_id: "Q55gFGMqXDqpES5gN",
  };

  const sendMessage = () => {
    if (!emailData || !messageData) {
      setInputEmpty(true);
      return;
    }

    data.message_data.message = messageData + ` <from: ${emailData}>`;
    // data.message_data.reply_to = emailData;

    setProcessing(true);
    send(data.service_id, data.template_id, data.message_data, data.user_id)
      .then((response) => {
        console.log("SUCCESS", response.status, response.text);
        setSuccess(true);
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setError(true);
      });
    // na koniec musi być wyczyszczenie inputów i komunikat, że wiadomść została wysłana
  };

  const handleSuccess = () => {
    setProcessing(false);
    setSuccess(false);
  };

  const handleError = () => {
    setProcessing(false);
    setError(false);
  };

  return (
    <StyledContactForm>
      {!isProcessing && (
        <form>
          <InputElem
            type="email"
            label="email"
            action={setEmailData}
            validation={setValid}
            empty={isInputEmpty}
            valid={isValid}
          />
          <InputElem
            type="textarea"
            label="message"
            action={setMessageData}
            empty={isInputEmpty}
          />
          <FormButton
            type="button"
            text={t("views.contact.buttons.submit")}
            action={sendMessage}
            title={t("common.a11y.submit")}
          />
        </form>
      )}
      {isProcessing && !isSuccess && !isError && (
        <StyledSendingEffectContainer>
          <SpinnerHex />
        </StyledSendingEffectContainer>
      )}
      {isProcessing && isSuccess && (
        <StyledSendingEffectContainer
          theme={$tc.setContrastTheme(isContrast, isTheme)}
        >
          <p>
            {t("views.contact.messages.success")}
            <br></br>
            {t("views.contact.messages.succesText")}
          </p>
          <FormButton
            type="button"
            text="OK"
            action={handleSuccess}
            title={t("common.a11y.submitReaction")}
          />
        </StyledSendingEffectContainer>
      )}
      {isProcessing && isError && (
        <StyledSendingEffectContainer
          theme={$tc.setContrastTheme(isContrast, isTheme)}
        >
          <p>
            {t("views.contact.messages.failed")}
            <br></br>
            {t("views.contact.messages.failedText")}
          </p>
          <FormButton
            type="button"
            text="OK"
            action={handleError}
            title={t("common.a11y.submitReaction")}
          />
        </StyledSendingEffectContainer>
      )}
    </StyledContactForm>
  );
};

export default ContactForm;
