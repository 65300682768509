import { useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode as faCode } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faGithub,
  faGitlab,
  faCodepen,
  faStackOverflow,
} from "@fortawesome/free-brands-svg-icons";
import ResumeLink from "../elems/ResumeLink";
import { useTranslation } from "react-i18next";

const ContactSocials = () => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);
  const { t } = useTranslation();

  return (
    <>
      <ResumeLink
        link="https://www.linkedin.com/in/pawe%C5%82-nieczuja-ostrowski-6b084a88/"
        title={t("common.a11y.openBlank", { name: "linkedIn" })}
        icon={faLinkedin}
        small
      />
      <ResumeLink
        link="https://profile.codersrank.io/user/web-ski/"
        title={t("common.a11y.openBlank", { name: "codersrank" })}
        icon={faCode}
        small
      />
      <ResumeLink
        link="https://github.com/Web-ski"
        title={t("common.a11y.openBlank", { name: "github" })}
        icon={faGithub}
        small
      />
      <ResumeLink
        link="https://gitlab.com/Web-ski"
        title={t("common.a11y.openBlank", { name: "gitlab" })}
        icon={faGitlab}
        small
      />
      <ResumeLink
        link="https://stackoverflow.com/users/13577530/web-ski"
        title={t("common.a11y.openBlank", { name: "stackoverflow" })}
        icon={faStackOverflow}
        small
      />
      <ResumeLink
        link="https://codepen.io/Web-ski"
        title={t("common.a11y.openBlank", { name: "codepen" })}
        icon={faCodepen}
        small
      />
    </>
  );
};

export default ContactSocials;
