import { useState, useEffect, useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import styled from "styled-components";
import Hex from "./Hex";

const StyledButtonHex = styled.div`
  position: relative;
  display: flex;
  background-color: transparent;
  border: none;
  outline: 0;
  margin: 0;
  padding: 0;
  width: ${(props) => (props.small ? "60px" : "100px")};
  height: ${(props) => (props.small ? "48px" : "80px")};
  transition: 0.2s;

  > svg {
    /* fill: var(--color-primary-lighter); */
    /* backdrop-filter: blur(20px); */

    > polygon:nth-of-type(2) {
      /* fill: var(--color-bg-light); */
      /* backdrop-filter: blur(10px); */

      .dark-theme & {
        /* fill: var(--color-bg-dark); */
        /* fill: rgba(255, 255, 255, 0.25); */
        /* filter: blur(20px); */
        /* fill-opacity: 0.8; */
      }
    }
  }

  .theme-mark-icon {
    width: 1.5rem;
    height: 1.5rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.icon-dots {
      background-image: ${(props) => "url(" + props.theme.iconDots + ")"};
    }
    &.icon-moon-and-sun {
      background-image: ${(props) => "url(" + props.theme.iconMoonAndSun + ")"};
    }
    &.icon-contrast {
      background-image: ${(props) => "url(" + props.theme.iconContrast + ")"};
    }
  }

  &.powerButton {
    display: none;

    .off & {
      display: flex;
      > svg {
        /* stroke: var(--color-primary-light); */
        /* filter: var(--button-border-shadow); */

        /* .dark-theme & {
          stroke: var(--color-primary-dark);
        } */
      }
    }
  }

  &.active {
    button {
      color: ${(props) => props.theme.colorTitle};
      /* outline: dashed 1px ${(props) => props.theme.colorTitle};
      outline-offset: -5px; */
      text-decoration: ${(props) => props.theme.a11yTextDecoration};
      text-underline-offset: 3px;
      text-decoration-thickness: 2px;
      /* text-shadow: -10px 0px 0 #d32, 10px 0px 0 #d32, 0 0 0 #d32; */
    }
  }

  &:hover {
    > svg {
      /* fill: var(--color-primary-lighter); */

      > polygon:nth-of-type(2) {
        /* fill: ${(props) => props.theme.colorSecondary}; */
        fill: ${(props) => props.theme.colorBackgroundHover};
      }
    }

    > button {
      color: ${(props) => props.theme.colorHover};
    }

    .theme-mark-icon {
      &.icon-dots {
        background-image: ${(props) =>
          "url(" + props.theme.iconDotsHover + ")"};
      }
      &.icon-moon-and-sun {
        background-image: ${(props) =>
          "url(" + props.theme.iconMoonAndSunHover + ")"};
      }
      &.icon-contrast {
        background-image: ${(props) =>
          "url(" + props.theme.iconContrastHover + ")"};
      }
    }
  }
`;

const StyledButton = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  cursor: pointer;
  /* background-color: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
  border: none;
  outline: 0;
  margin: auto;
  padding: 0;
  border-radius: 50%;
  width: ${(props) => (props.small ? "40px" : "88px")};
  height: ${(props) => (props.small ? "40px" : "80px")};
  transition: 0.2s;
  color: ${(props) => props.theme.colorPrimary};

  img {
    height: 20px;
  }

  span {
    font-family: var(--text-title);
    font-size: ${(props) => (props.chartIcon ? "2rem" : "0.8rem")};
    text-transform: uppercase;
    line-height: 1;
  }

  .icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
  }

  .icon-stack {
    background-image: url("/icons/blacksmith.png");
  }

  &:focus-visible {
    color: ${(props) => props.theme.colorFocus}; // zrobic też dla active
    ~ svg {
      > polygon:nth-of-type(2) {
        fill: ${(props) => props.theme.colorBackgroundFocus};
      }
    }

    .active && {
      color: ${(props) => props.theme.colorFocus};
    }
  }
`;

const ButtonHex = ({
  children,
  active,
  action,
  name,
  light,
  small,
  section,
  chartIcon,
  id,
  disabled,
  title,
}) => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);
  const [isActive, setActive] = useState(active);
  const handleButton = () => {
    // setActive(!isActive);
    action && action(section);
  };
  useEffect(() => {
    // console.log(section, active);
    setActive(active);
  }, [active]);
  return (
    <StyledButtonHex
      theme={$tc.setContrastTheme(isContrast, isTheme)}
      small={small}
      light={light}
      className={(isActive ? "active" : "") + " " + (name || "")}
      id={id}
    >
      <StyledButton
        small={small}
        onClick={handleButton}
        theme={$tc.setContrastTheme(isContrast, isTheme)}
        chartIcon={chartIcon}
        disabled={disabled}
        title={title}
      >
        {children}
      </StyledButton>
      <Hex small={small} />
    </StyledButtonHex>
  );
};

export default ButtonHex;
