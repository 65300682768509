import React, { createContext } from "react";

export const globalCockpit = {
  hide: false,
  show: "cockpit",
};

export const CockpitContext = createContext({
  isCockpit: globalCockpit.hide,
  setCockpit: (cockpit) => {},
});
