import React from "react";
import ImageFigure from "../../elems/ImageFigure";
import ParagraphLink from "../../elems/ParagraphLink";
import SectionParagraph from "../../elems/SectionParagraph";
import SectionSubtitle from "../../elems/SectionSubtitle";
import { StyledImagesTemplate } from "../../templates/StyledImagesTemplate";
import { useTranslation, Trans } from "react-i18next";

const AboutContentThree = () => {
  const { t } = useTranslation();
  return (
    <>
      <SectionSubtitle>{t("views.about.content.three.title")}</SectionSubtitle>
      <SectionParagraph>
        {t("views.about.content.three.text.p1")}
      </SectionParagraph>
      <SectionParagraph>
        <Trans i18nKey="views.about.content.three.text.p2">
          <ParagraphLink
            link="https://www.apsl.edu.pl/"
            title={t("common.a11y.openBlank", {
              name: "Pomeranian University",
            })}
          ></ParagraphLink>
        </Trans>
      </SectionParagraph>
    </>
  );
};

export default AboutContentThree;
