import styled from "styled-components";
import Hero from "../modules/Hero";
import { StyledSectionTemplate } from "../templates/StyledSectionTemplate";

const StyledWelcomeSection = styled(StyledSectionTemplate)`
  padding-top: 30px;
`;

const WelcomeSection = () => {
  return (
    <StyledWelcomeSection>
      <Hero />
    </StyledWelcomeSection>
  );
};

export default WelcomeSection;
