import { useContext } from "react";
import { ThemeContext, themes } from "../context/theme-context";
import styled from "styled-components";

const StyledSectionSubtitle = styled.h2`
  line-height: 1;
  text-align: ${(props) => props.center && "center"};
  font-family: var(--text-title);
  color: ${(props) => props.theme.colorTitle};
  text-shadow: ${(props) => props.theme.shadowTitle};
  font-size: 1.1rem;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  margin-top: 1rem;

  @media (min-width: 992px) {
    font-size: 1.2rem;
  }
`;

const SectionSubtitle = ({ children, center }) => {
  const { isTheme } = useContext(ThemeContext);

  return (
    <StyledSectionSubtitle theme={themes[isTheme]} center={center}>
      {children}
    </StyledSectionSubtitle>
  );
};

export default SectionSubtitle;
