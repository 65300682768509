import { useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import styled from "styled-components";
const levels = [1, 2, 3];

const StyledSkillsBar = styled.div`
  margin: 6px 0;
  display: flex;
`;

const StyledBarPart = styled.svg`
  width: 30px;
  height: 16px;
  /* border: solid 1px var(--color-secondary-light); */
  /* margin-right: 2px; */
  background-color: transparent;
  stroke: ${(props) => props.theme.colorSecondary};
  stroke-width: 1;
  stroke-linecap: square;
  fill: transparent;

  &.filled {
    &:nth-of-type(1) {
      > polygon {
        fill: url("#myGradientLight1");
      }
    }

    &:nth-of-type(2) {
      > polygon {
        fill: url("#myGradientLight2");
      }
    }

    &:nth-of-type(3) {
      > polygon {
        fill: url("#myGradientLight3");
      }
    }
  }
`;

const SkillsBar = ({ level }) => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);

  return (
    <StyledSkillsBar>
      {levels.map((item) => (
        <StyledBarPart
          key={item}
          className={item <= level && "filled"}
          theme={$tc.setContrastTheme(isContrast, isTheme)}
        >
          <defs>
            <linearGradient id={"myGradientLight" + item}>
              <stop
                offset="0"
                stopColor={
                  $tc.setContrastTheme(isContrast, isTheme).colorDanger
                }
              >
                <animate
                  dur="1s"
                  attributeName="offset"
                  fill="freeze"
                  begin={item + "s"}
                  from="0"
                  to="1"
                />
              </stop>
              <stop offset="0" stopColor="transparent">
                <animate
                  dur="1s"
                  attributeName="offset"
                  fill="freeze"
                  begin={item + "s"}
                  from="0"
                  to="1"
                />
              </stop>
            </linearGradient>
          </defs>
          <polygon points="6,1 30,1 24,14, 0,14"></polygon>
        </StyledBarPart>
      ))}
    </StyledSkillsBar>
  );
};

export default SkillsBar;
