import { useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import styled from "styled-components";

const StyledSectionTitle = styled.h1`
  line-height: 1.2;
  /* text-align: center; */
  z-index: 2;
  font-family: var(--text-title);
  color: ${(props) => props.theme.colorTitle};
  text-shadow: ${(props) => props.theme.shadowTitle};
  font-size: 1.5rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;

  + p {
    font-size: 1.1rem;
  }

  /* .dark-theme & {
    color: var(--color-title-dark);
  } */

  @media (min-width: 992px) {
    font-size: 2rem;

    + p {
      font-size: 1.2rem;
    }
  }
`;

const SectionTitle = ({ children }) => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);

  return (
    <StyledSectionTitle theme={$tc.setContrastTheme(isContrast, isTheme)}>
      {children}
    </StyledSectionTitle>
  );
};

export default SectionTitle;
