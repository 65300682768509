import { useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import styled from "styled-components";

const StyledButton = styled.button`
  display: block;
  font-family: var(--text-title);
  font-size: 0.8rem;
  line-height: 1;
  color: ${(props) => props.theme.colorPrimary};
  margin: 20px 0 10px auto;
  background-color: transparent;
  cursor: pointer;
  border: solid 2px ${(props) => props.theme.colorSecondary};
  border-radius: 5px;
  padding: 10px 15px;
  transition: 0.2s;

  @media (min-width: 992px) {
    margin: 20px 10px 10px auto;
  }

  &:hover {
    background-color: ${(props) => props.theme.colorBackgroundHover};
    color: ${(props) => props.theme.colorHover};
  }

  &:focus-visible {
    background-color: ${(props) => props.theme.colorBackgroundFocus};
    color: ${(props) => props.theme.colorFocus};
    ${(props) => props.theme.outline};
  }
`;

const FormButton = ({ type, text, action, icon, title }) => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);

  return (
    <StyledButton
      type={type}
      onClick={action}
      theme={$tc.setContrastTheme(isContrast, isTheme)}
      title={title}
    >
      {icon} {text}
    </StyledButton>
  );
};

export default FormButton;
