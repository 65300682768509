import { useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import styled from "styled-components";

const StyledSpinnerHex = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;

  svg {
    position: absolute;
    background-color: transparent;
    fill: transparent;
    transition: 0.2s;
    stroke-linecap: round;

    line {
      stroke-width: 6;
      stroke: transprent;
      animation: pulsar var(--spinner-hex-time) steps(3, end) infinite;
    }

    line:nth-of-type(1),
    line:nth-of-type(2) {
      animation-delay: 0s;
    }

    line:nth-of-type(4),
    line:nth-of-type(5) {
      animation-delay: calc(var(--spinner-hex-time) * 0.33);
    }

    line:nth-of-type(3),
    line:nth-of-type(6) {
      animation-delay: calc(var(--spinner-hex-time) * 0.66);
    }
  }

  @keyframes pulsar {
    from {
      stroke: transparent;
    }
    to {
      stroke: ${(props) => props.theme.colorPrimary};
    }
  }
`;

const SpinnerHex = () => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);

  return (
    <StyledSpinnerHex theme={$tc.setContrastTheme(isContrast, isTheme)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="80">
        <line x1="28" y1="3" x2="72" y2="3" />
        <line x1="28" y1="77" x2="72" y2="77" />

        <line x1="4" y1="35" x2="22" y2="8" />
        <line x1="4" y1="43" x2="22" y2="72" />

        <line x1="97" y1="35" x2="78" y2="8" />
        <line x1="97" y1="43" x2="78" y2="72" />
      </svg>
    </StyledSpinnerHex>
  );
};

export default SpinnerHex;
