import React from "react";
import SectionSubtitle from "../../elems/SectionSubtitle";
import { STACK_DATA } from "../../const/stack.const";
import StackModule from "../../modules/StackModule";
import { useTranslation } from "react-i18next";

const FrontContent = (title) => (
  <>
    <SectionSubtitle center>{title}</SectionSubtitle>
    <div>
      {STACK_DATA.front.map((element) => (
        <StackModule data={element} key={element.name} />
      ))}
    </div>
  </>
);

const BackendContent = (title) => (
  <>
    <SectionSubtitle center>{title}</SectionSubtitle>
    <div>
      {STACK_DATA.back.map((element) => (
        <StackModule data={element} key={element.name} />
      ))}
    </div>
  </>
);

const DesignContent = (title) => (
  <>
    <SectionSubtitle center>{title}</SectionSubtitle>
    <div>
      {STACK_DATA.design.map((element) => (
        <StackModule data={element} key={element.name} />
      ))}
    </div>
  </>
);

const TestsContent = (title) => (
  <>
    <SectionSubtitle center>{title}</SectionSubtitle>
    <div>
      {STACK_DATA.tests.map((element) => (
        <StackModule data={element} key={element.name} />
      ))}
    </div>
  </>
);

const ToolsContent = (title) => (
  <>
    <SectionSubtitle center>{title}</SectionSubtitle>
    <div>
      {STACK_DATA.develop.map((element) => (
        <StackModule data={element} key={element.name} />
      ))}
    </div>
  </>
);

const StackContent = ({ content }) => {
  const { t } = useTranslation();
  return (
    <>
      {content === 1 && FrontContent(t("views.stack.subtitles.front"))}
      {content === 2 && ToolsContent(t("views.stack.subtitles.dev"))}
      {content === 3 && TestsContent(t("views.stack.subtitles.test"))}
      {content === 4 && BackendContent(t("views.stack.subtitles.back"))}
      {content === 5 && DesignContent(t("views.stack.subtitles.design"))}
    </>
  );
};

export default StackContent;
