import React, { useContext } from "react";
import styled from "styled-components";
import ButtonHex from "../elems/ButtonHex";
import { SectionContext, globalSection } from "../context/section-context";
import { CockpitContext, globalCockpit } from "../context/cockpit-context";
import { useTranslation } from "react-i18next";

const StyledNavs = styled.nav`
  position: relative;
  margin: 0px auto auto auto;
  height: 166px;
  width: calc(85px * 3);

  > div {
    position: absolute;
    top: 0;
  }

  > div:nth-of-type(1) {
    left: 0;
  }

  > div:nth-of-type(2) {
    left: 0;
    top: 87px;
  }

  > div:nth-of-type(3) {
    left: calc(78px * 1);
    top: 44px;
  }

  > div:nth-of-type(4) {
    left: calc(78px * 2);
    top: 0;
  }

  > div:nth-of-type(5) {
    left: calc(78px * 2);
    top: 87px;
  }

  @media screen and (min-width: 778px) {
    width: calc(82px * 5);
    height: 120px;
    top: 0;

    > div:nth-of-type(even) {
      top: 44px;
    }

    > div:nth-of-type(1) {
      left: 0;
    }

    > div:nth-of-type(2) {
      left: 78px;
    }

    > div:nth-of-type(3) {
      top: 0;
      left: calc(78px * 2);
    }

    > div:nth-of-type(4) {
      left: calc(78px * 3);
    }

    > div:nth-of-type(5) {
      top: 0;
      left: calc(78px * 4);
    }
  }

  .off & {
    display: none;
  }
`;

const Navs = () => {
  const { t } = useTranslation();
  const section = useContext(SectionContext);
  const cockpit = useContext(CockpitContext);

  const handleSection = (choosenSection) => {
    // setPower(!isPower);
    // console.log("Section is " + section.isSection);
    section.setSection(choosenSection);

    if (choosenSection !== section.isSection) {
      if (window.innerWidth < 992) {
        cockpit.setCockpit(
          cockpit.isCockpit ? globalCockpit.hide : globalCockpit.show
        );
      }
    }
  };

  return (
    <StyledNavs>
      <ButtonHex
        action={handleSection}
        active={section.isSection === globalSection.welcome}
        section={globalSection.welcome}
        disabled={section.isSection === globalSection.welcome}
        title={t("common.a11y.start")}
      >
        {/* <span className="spec-chart">&#9728;</span> */}
        <span>{t("common.start")}</span>
      </ButtonHex>
      <ButtonHex
        action={handleSection}
        active={section.isSection === globalSection.about}
        section={globalSection.about}
        disabled={section.isSection === globalSection.about}
        title={t("common.a11y.about")}
      >
        <span>{t("common.about")}</span>
      </ButtonHex>
      <ButtonHex
        action={handleSection}
        active={section.isSection === globalSection.stack}
        section={globalSection.stack}
        disabled={section.isSection === globalSection.stack}
        title={t("common.a11y.stack")}
      >
        {/* <div className="icon icon-stack"></div> */}
        <span>{t("common.stack")}</span>
      </ButtonHex>
      <ButtonHex
        action={handleSection}
        active={section.isSection === globalSection.resume}
        section={globalSection.resume}
        disabled={section.isSection === globalSection.resume}
        title={t("common.a11y.resume")}
      >
        <span>{t("common.resume")}</span>
      </ButtonHex>
      <ButtonHex
        action={handleSection}
        active={section.isSection === globalSection.contact}
        section={globalSection.contact}
        disabled={section.isSection === globalSection.contact}
        title={t("common.a11y.contact")}
        // chartIcon
      >
        {/* <span>&#9993;</span> */}
        <span>{t("common.contact")}</span>
      </ButtonHex>
    </StyledNavs>
  );
};

export default Navs;

// <a href="https://www.flaticon.com/free-icons/brain" title="brain icons">Brain icons created by Freepik - Flaticon</a>
