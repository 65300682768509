import { useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import styled from "styled-components";

const StyledSectionParagraph = styled.p`
  line-height: 1.6;
  /* font-family: var(--text-title); */
  color: ${(props) => props.theme.colorTitle};
  font-size: 0.9rem;
  letter-spacing: 1px;
  /* font-weight: lighter; */
  margin-bottom: 10px;
  /* margin: auto; */

  @media (min-width: 992px) {
    font-size: 1rem;
  }
`;

const SectionParagraph = ({ children }) => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);

  return (
    <StyledSectionParagraph theme={$tc.setContrastTheme(isContrast, isTheme)}>
      {children}
    </StyledSectionParagraph>
  );
};

export default SectionParagraph;
