import React from "react";
import { GENERAL_DATA } from "../const/general.const";
import { languageSwitch } from "../mixins/lang";
import { Helmet } from "react-helmet-async";

const Head = () => {
  // console.log("head localStorage", localStorage);
  languageSwitch();
  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link rel="icon" type="image/png" href="favicon.png" />
      <link
        href="https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@700&display=swap"
        rel="stylesheet"
      />
      <title>{GENERAL_DATA.author} portfolio</title>
      <link rel="canonical" href="http://mysite.com/example" />
    </Helmet>
  );
};

export default Head;
