import styled from "styled-components";
import { useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";

const StyledBioDecorator = styled.div`
  position: absolute;
  top: ${(props) => props.decor.renderPosTop()};
  left: ${(props) => props.decor.renderPosLeft()};
  /* background: ${(props) => props.theme.colorBackgroundHover}; */
  background-image: radial-gradient(
    ${(props) => props.theme.colorPrimary},
    ${(props) => props.theme.colorSecondary}
  );
  border-radius: 50%;
  height: ${(props) => props.decor.renderRadius()};
  /* height: 20px; */
  /* width: 20px; */
  aspect-ratio: 1/1;
  opacity: ${(props) => props.decor.renderOpacity()};
  animation-name: ${(props) => "anim" + props.index};
  animation-duration: ${(props) => props.decor.renderTimeFunctioning()};
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;

  @keyframes ${(props) => "anim" + props.index} {
    0% {
      top: ${(props) => props.decor.renderPosTop()};
      left: ${(props) => props.decor.renderPosLeft()};
    }
    /* 10% {
      transform: translate(10px, 50px);
    }
    20% {
      transform: translate(12px, 20px);
    }
    30% {
      transform: translate(15px, 30px);
    }
    40% {
      transform: translate(20px, 40px);
    }
    50% {
      transform: translate(25px, 50px);
    } */

    /* 50% {
      top: ${(props) => props.decor.renderPosTop()};
      left: ${(props) => props.decor.renderPosLeft()};
    }
    100% {
      top: ${(props) => props.decor.renderPosTop()};
      left: ${(props) => props.decor.renderPosLeft()};
    } */
  }
`;

const BioDecorator = ({ index, decor }) => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);
  // console.log(decor.renderPosTop());
  // tu powinienem stworzyć obiekt?

  return (
    <StyledBioDecorator
      index={index}
      decor={decor}
      theme={$tc.setContrastTheme(isContrast, isTheme)}
    />
  );
};

export default BioDecorator;
