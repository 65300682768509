import { useState } from "react";
import styled from "styled-components";
import {
  StyledButtonsCentralWrapper,
  StyledButtonCentral,
} from "./BoxSwitcherElems/StyledButtonsCentral";
import {
  StyledButtonsLRWrapper,
  StyledButtonLR,
} from "./BoxSwitcherElems/StyledButtonsLR";

const StyledMainBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  /* min-width: 600px; */
  /* margin: 10px;
  padding: 10px; */
  width: 100%;
  height: 100%;
  /* max-width: 620px; */
  margin: 0 auto;

  @media (min-width: 992px) {
    max-width: 700px;
  }

  @media (min-width: 1200px) {
    max-width: 1000px;
  }
`;

// const StyledCentralWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: start;
//   align-items: center;
// `;

const StyledContentWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-y: auto; */

  @media (min-width: 992px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const StyledChildWrapper = styled.div`
  margin: 0 15px;
  /* padding: 10px; */
  border-radius: 10px;
  /* border: dotted 2px var(--color-primary-light); */
  display: flex;
  flex-direction: column;
  transition: 0.2s;

  @media (min-width: 992px) {
    margin: 0 30px;
  }

  .dark-theme & {
    /* border-color: var(--color-secondary-dark); */
  }

  &:not(.active) {
    display: none;
  }

  &.active {
    display: flex;
    animation: showContent 1s ease-in forwards;
  }

  @keyframes showContent {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const BoxSwitcher = ({ children }) => {
  const [isActive, setActive] = useState(0);
  const handleBox = (index) => {
    setActive(index);
  };
  const setPrevBox = () => {
    isActive > 0 && setActive(isActive - 1);
  };
  const setNextBox = () => {
    isActive < children.length - 1 && setActive(isActive + 1);
  };

  return (
    <StyledMainBoxWrapper>
      <StyledButtonsCentralWrapper>
        {children.map((item, index) => {
          return (
            <div key={index}>
              <StyledButtonCentral
                className={isActive === index ? "active" : ""}
                onClick={() => handleBox(index)}
              >
                {index + 1}
              </StyledButtonCentral>
              {index + 1 < children.length && <span></span>}
            </div>
          );
        })}
      </StyledButtonsCentralWrapper>

      <StyledContentWrapper>
        <StyledButtonsLRWrapper>
          <StyledButtonLR onClick={() => setPrevBox()} disabled={isActive <= 0}>
            <span>PREV</span>
          </StyledButtonLR>
        </StyledButtonsLRWrapper>
        {children.map((item, index) => {
          return (
            <StyledChildWrapper
              key={index}
              className={isActive === index ? "active" : ""}
            >
              {item}
            </StyledChildWrapper>
          );
        })}
        <StyledButtonsLRWrapper>
          <StyledButtonLR
            onClick={() => setNextBox()}
            disabled={isActive >= children.length - 1}
          >
            <span>NEXT</span>
          </StyledButtonLR>
        </StyledButtonsLRWrapper>
      </StyledContentWrapper>
    </StyledMainBoxWrapper>
  );
};

export default BoxSwitcher;
