import React, { createContext } from "react";

export const globalSection = {
  welcome: "welcome-section",
  about: "about-section",
  stack: "stack-section",
  resume: "resume-section",
  contact: "contact-section",
};

export const SectionContext = createContext({
  isSection: globalSection.welcome,
  setSection: (section) => {},
});
