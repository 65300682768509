import { useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import styled from "styled-components";

const StyledParagraphLink = styled.a`
  color: ${(props) => props.theme.colorPrimary};
  text-decoration-style: dotted;
  text-underline-offset: 2px;

  &:hover {
    color: ${(props) => props.theme.colorTitle};
  }
  /* margin: auto; */
`;

const ParagraphLink = ({ children, link, title, target }) => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);

  return (
    <StyledParagraphLink
      href={link}
      title={title}
      target={target}
      theme={$tc.setContrastTheme(isContrast, isTheme)}
    >
      {children}
    </StyledParagraphLink>
  );
};

export default ParagraphLink;
