export const STACK_DATA = {
  front: [
    {
      name: "HTML",
      image: "html5.png",
      level: 3,
    },
    {
      name: "CSS",
      image: "css3.png",
      level: 3,
    },
    {
      name: "SASS/SCSS",
      image: "sass.png",
      level: 3,
    },
    {
      name: "JavaScript",
      image: "js.png",
      level: 3,
    },
    {
      name: "TypeScript",
      image: "ts.png",
      level: 1,
    },
    {
      name: "Vue",
      image: "vue.png",
      level: 2,
    },
    {
      name: "Vue Router",
      image: "vue-router.png",
      level: 2,
    },
    {
      name: "Vuex",
      image: "vuex.png",
      level: 2,
    },
    {
      name: "Pinia",
      image: "pinia.svg",
      level: 1,
    },
    {
      name: "Vue i18n",
      image: "vue-i18n.svg",
      level: 2,
    },
    {
      name: "React",
      image: "react.png",
      level: 2,
    },
    {
      name: "React Router",
      image: "react-router.png",
      level: 2,
    },
    {
      name: "Redux",
      image: "redux.svg",
      level: 2,
    },
    {
      name: "Gatsby.js",
      image: "Gatsby.png",
      level: 2,
    },
    {
      name: "Next.js",
      image: "next.png",
      level: 1,
    },
    {
      name: "Svelte",
      image: "svelte.png",
      level: 1,
    },
    {
      name: "Element-UI",
      image: "element.png",
      level: 3,
    },
    {
      name: "Bootstrap",
      image: "Bootstrap.png",
      level: 2,
    },
    {
      name: "Tailwind",
      image: "tailwind.png",
      level: 3,
    },
    {
      name: "Styled components",
      image: "styled-components-color.png",
      level: 2,
    },
    {
      name: "jQuery",
      image: "jquery_original.svg",
      level: 1,
    },
    {
      name: "Angular",
      image: "Angular.png",
      level: 1,
    },
  ],
  tests: [
    {
      name: "Vue Test Utils",
      image: "vue.png",
      level: 1,
    },
    {
      name: "Jest",
      image: "jest.png",
      level: 1,
    },
    {
      name: "Playwright",
      image: "playwright.png",
      level: 1,
    },
  ],
  develop: [
    {
      name: "npm",
      image: "npm.png",
      level: 2,
    },
    {
      name: "Git",
      image: "git.png",
      level: 2,
    },
    {
      name: "Gitlab",
      image: "gitlab.png",
      level: 2,
    },
    {
      name: "Github",
      image: "github-black.png",
      level: 2,
    },
    {
      name: "gulp",
      image: "gulp.png",
      level: 2,
    },
    {
      name: "webpack",
      image: "webpack.png",
      level: 2,
    },
    {
      name: "prettier",
      image: "prettier.png",
      level: 2,
    },
    {
      name: "eslint",
      image: "eslint.png",
      level: 2,
    },
    {
      name: "Stroybook",
      image: "storybook.png",
      level: 1,
    },
    {
      name: "Jira",
      image: "jira.png",
      level: 2,
    },
    {
      name: "VSCode",
      image: "vscode.png",
      level: 3,
    },
    {
      name: "WebStorm",
      image: "webstorm.png",
      level: 3,
    },
    {
      name: "Slack",
      image: "slack.png",
      level: 2,
    },
    {
      name: "Teams",
      image: "teams.png",
      level: 2,
    },
    {
      name: "Trello",
      image: "trello-mark-blue.png",
      level: 1,
    },
    {
      name: "Asana",
      image: "asana-logo.svg",
      level: 1,
    },
  ],
  back: [
    {
      name: "Node.js",
      image: "node.svg",
      level: 1,
    },
    {
      name: "SQL/mySQL",
      image: "mySQL.png",
      level: 1,
    },
    {
      name: "PHP",
      image: "php.png",
      level: 1,
    },
  ],
  design: [
    {
      name: "Figma",
      image: "figma.png",
      level: 2,
    },
    {
      name: "Invision",
      image: "invision.png",
      level: 1,
    },
    {
      name: "Gimp",
      image: "gimp.svg",
      level: 2,
    },
    {
      name: "Balasamiq",
      image: "balsamiq-logo-noborder-screen.png",
      level: 1,
    },
    {
      name: "Photoshop",
      image: "photoshop.png",
      level: 1,
    },
    {
      name: "InDesign",
      image: "InDesign.png",
      level: 2,
    },
    {
      name: "Sketchbook",
      image: "sketchbook.png",
      level: 2,
    },
  ],
};

export const STACK_LEVELS = {
  1: "Beginer",
  2: "Intermediate",
  3: "Advanced",
};
