import { useContext, useEffect, useState } from "react";
import { ThemeContext, themes } from "../context/theme-context";
import styled from "styled-components";
import ButtonHex from "../elems/ButtonHex";
import { PowerContext, globalPower } from "../context/power-context";
import { ToolsClass } from "../class/tools.class.js";
import { useTranslation } from "react-i18next";

const StyledInitial = styled.div`
  margin: auto auto 20px auto;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  width: auto;
  display: none;

  .off & {
    margin: auto;
    display: flex;
    animation: setVisibilityOn 1s;

    @keyframes setVisibilityOn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  &.initial-off {
    animation: setVisibilityOff 1s forwards;

    @keyframes setVisibilityOff {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
`;

const StyledInitialTitle = styled.h1`
  line-height: 1.2;
  text-align: center;
  z-index: 2;
  font-family: var(--text-title);
  color: ${(props) => props.theme.colorTitle};
  font-size: 1rem;
  letter-spacing: 0.1;
  text-transform: none;
  margin-bottom: 20px;

  > strong {
    font-family: var(--text-title);
  }
`;

const Initial = () => {
  const { t } = useTranslation();
  const power = useContext(PowerContext);
  const [isVisible, setVisibility] = useState("initial-on");
  const { isTheme } = useContext(ThemeContext);

  const handlePower = () => {
    const changePower = () => {
      power.setPower(power.isPower ? globalPower.on : globalPower.off);
      setVisibility("initial-on");
    };
    setVisibility("initial-off");
    ToolsClass.changeTimer(changePower);
  };

  return (
    <StyledInitial className={isVisible}>
      <StyledInitialTitle theme={themes[isTheme]}>
        {t("views.initial.hello", { name: "Paweł" })}
        {/* {GENERAL_DATA.initial.one} */}
        {/* <strong>{GENERAL_DATA.author}</strong>! */}
      </StyledInitialTitle>
      <StyledInitialTitle as="h2" theme={themes[isTheme]}>
        {t("views.initial.question")}
      </StyledInitialTitle>
      <ButtonHex
        active={false}
        action={handlePower}
        name="powerButton"
        title={t("common.a11y.handlePower")}
      >
        <span>{power.isPower ? t("common.on") : t("common.off")}</span>
      </ButtonHex>
    </StyledInitial>
  );
};

export default Initial;
