import { TIMEOFF, LOCAL_THEME } from "../const/tools.const";
import { defaultTheme, contrastTheme } from "../context/theme-context";

export class ToolsClass {
  static changeTimer(f) {
    const timeout = TIMEOFF;
    return setTimeout(f, timeout);
  }

  static setThemeLocal(theme) {
    window.localStorage.setItem(LOCAL_THEME, theme);
  }

  static setContrastTheme(contrast, theme) {
    return contrast ? contrastTheme[theme] : defaultTheme[theme];
  }
}
