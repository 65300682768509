import styled from "styled-components";
// import { STACK_LEVELS } from "../const/stack.const";
import { useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import SkillsBar from "../elems/SkillsBar";

const StyledModuleContainer = styled.div`
  margin: 1rem 0;

  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    margin: 1rem;
    min-width: 200px;
  }
`;

const StyledModuleTitle = styled.h3`
  color: ${(props) => props.theme.colorPrimary};
`;

const StyledModuleIcon = styled.div`
  width: 60px;
  height: 40px;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media (min-width: 992px) {
    width: 70px;
    height: 50px;
  }
`;

// const StyledModuleIconLight = styled(StyledModuleIcon)`
//   display: flex;
//   .dark-theme & {
//     display: none;
//   }
// `;

// const StyledModuleIconDark = styled(StyledModuleIcon)`
//   display: none;
//   .dark-theme & {
//     display: flex;
//   }
// `;

const StyledModuleContentBox = styled.div`
  margin-top: 1rem;

  @media (min-width: 992px) {
    margin-left: 10px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const StackModule = ({ data }) => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);
  const url = "icons/";
  // const lightUrl = url + "light/";
  // const darkUrl = url + "dark/";
  return (
    <StyledModuleContainer>
      <StyledModuleIcon image={url + data.image} />
      {/* <StyledModuleIconLight image={lightUrl + data.image} />
      <StyledModuleIconDark image={darkUrl + data.image} /> */}
      <StyledModuleContentBox>
        <StyledModuleTitle theme={$tc.setContrastTheme(isContrast, isTheme)}>
          {data.name}
        </StyledModuleTitle>
        <SkillsBar level={data.level} />
        {/* <p>{STACK_LEVELS[data.level]}</p> */}
      </StyledModuleContentBox>
    </StyledModuleContainer>
  );
};

export default StackModule;
