import { useContext } from "react";
import { ThemeContext, themes } from "../context/theme-context";
import styled from "styled-components";

const StyledPageSubtitle = styled.p`
  color: white;
  line-height: 1.1;
  text-align: center;
  z-index: 2;
  font-family: var(--text-title);
  color: ${(props) => props.theme.colorTitle};
  font-size: 1.5rem;
  margin-bottom: 1rem;

  .dark-theme & {
    color: var(--color-title-dark);
  }

  @media (min-width: 992px) {
    font-size: 2rem;
  }
`;

const PageSubtitle = ({ children }) => {
  const { isTheme } = useContext(ThemeContext);
  return (
    <StyledPageSubtitle theme={themes[isTheme]}>{children}</StyledPageSubtitle>
  );
};

export default PageSubtitle;
