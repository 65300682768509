import React, { useState, useEffect } from "react";
import { PowerContext, globalPower } from "./context/power-context";
import { SectionContext, globalSection } from "./context/section-context";
import {
  ThemeContext,
  globalTheme,
  ContrastContext,
  globalContrast,
} from "./context/theme-context";
import { CockpitContext, globalCockpit } from "./context/cockpit-context";
import Parallax from "./modules/Parallax";
import AppBottomBar from "./structures/AppBottomBar";
import AppHeader from "./structures/AppHeader";
import InitialSection from "./structures/InitialSection";
import WelcomeSection from "./structures/WelcomeSection";
import AboutSection from "./structures/AboutSection";
import ResumeSection from "./structures/ResumeSection";
import ContactSection from "./structures/ContactSection";
import { StyledGeneralTemplate } from "./templates/StyledGeneralTemplate";
import { LOCAL_THEME } from "./const/tools.const";
import AppLeftBar from "./structures/AppLeftBar";
import AppRightBar from "./structures/AppRightBar";
import HandleCockpitButton from "./elems/HandleCockpitButton";
import StackSection from "./structures/StackSection";

const App = () => {
  const [isPower, setPower] = useState(globalPower.off);
  const [isTheme, setTheme] = useState(globalTheme.light);
  const [isContrast, setContrast] = useState(globalContrast.off);
  const [isSection, setSection] = useState(globalSection.welcome);
  const [isCockpit, setCockpit] = useState(
    window.innerWidth < 992 ? globalCockpit.hide : globalCockpit.show
  );

  const getLocalTheme = () => {
    const localTheme = window.localStorage.getItem(LOCAL_THEME);
    if (localTheme) {
      isTheme !== localTheme && setTheme(localTheme);
    }
  };

  getLocalTheme();

  return (
    <PowerContext.Provider value={{ isPower, setPower }}>
      <ContrastContext.Provider value={{ isContrast, setContrast }}>
        <ThemeContext.Provider value={{ isTheme, setTheme }}>
          <CockpitContext.Provider value={{ isCockpit, setCockpit }}>
            <div
              className={`App ${isPower || ""} ${isTheme || ""} ${
                isCockpit || ""
              }`}
            >
              <Parallax />
              <SectionContext.Provider value={{ isSection, setSection }}>
                <StyledGeneralTemplate>
                  <HandleCockpitButton />
                  <AppHeader />
                  <AppLeftBar />
                  <InitialSection />
                  {isSection === globalSection.welcome && <WelcomeSection />}
                  {isSection === globalSection.about && <AboutSection />}
                  {isSection === globalSection.stack && <StackSection />}
                  {isSection === globalSection.resume && <ResumeSection />}
                  {isSection === globalSection.contact && <ContactSection />}
                  <AppRightBar />
                  <AppBottomBar />
                </StyledGeneralTemplate>
              </SectionContext.Provider>
            </div>
          </CockpitContext.Provider>
        </ThemeContext.Provider>
      </ContrastContext.Provider>
    </PowerContext.Provider>
  );
};

export default App;
