import { useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import styled from "styled-components";

const StyledPageTitle = styled.h1`
  line-height: 1;
  text-align: center;
  z-index: 2;
  font-family: var(--text-title);
  color: ${(props) => props.theme.colorTitle};
  text-shadow: ${(props) => props.theme.shadowTitle};
  font-size: 2.4rem;
  letter-spacing: 0.1;
  text-transform: uppercase;
  margin-bottom: 1rem;

  /* .dark-theme & {
    color: var(--color-title-dark);
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  } */

  @media (min-width: 992px) {
    font-size: 3rem;
  }
`;

const PageTitle = ({ children }) => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);

  return (
    <StyledPageTitle
      className={isContrast ? "yes" : "no"}
      theme={$tc.setContrastTheme(isContrast, isTheme)}
    >
      {children}
    </StyledPageTitle>
  );
};

export default PageTitle;
