import React, { useEffect, useRef, useState, useContext } from "react";
import styled from "styled-components";
import SectionTitle from "../elems/SectionTitle";
import { StyledSectionTemplate } from "../templates/StyledSectionTemplate";
import { StyledTitleTemplate } from "../templates/StyledTitleTemplate";
import AboutContentOne from "./AboutSection/AboutContentOne";
import AboutContentThree from "./AboutSection/AboutContentThree";
import AboutContentTwo from "./AboutSection/AboutContentTwo";
import { ThemeContext, themes } from "../context/theme-context";
import { useTranslation } from "react-i18next";

const StyledAboutSection = styled(StyledSectionTemplate)``;

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  /* height: 100%; */
  overflow: hidden;

  /* > .hej {
    overflow: hidden;
  } */
`;

const StyledContentWrapper = styled.div`
  padding: 0 5px;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }

  mask-mode: alpha;

  &.text-box-long--center {
    mask-image: linear-gradient(
      transparent,
      black,
      black,
      black,
      black,
      black,
      transparent
    );
  }

  &.text-box-long--top {
    mask-image: linear-gradient(
      black,
      black,
      black,
      black,
      black,
      black,
      transparent
    );
  }

  &.text-box-long--bottom {
    mask-image: linear-gradient(
      transparent,
      black,
      black,
      black,
      black,
      black,
      black
    );
  }
`;

const StyledArticleContainer = styled.article`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  /* opacity: 0.1; */
  &:first-child {
    margin-top: 1rem;
  }

  @media (min-width: 992px) {
    &:last-child {
      margin-bottom: 110px;
    }
  }
`;

const AboutSection = () => {
  const { t } = useTranslation();
  const { isTheme } = useContext(ThemeContext);
  const [isOpacityClass, setIsOpacityClass] = useState("");
  const contentRef = useRef();
  const wrapperRef = useRef();

  const checkIsTextBoxLong = () => {
    return contentRef.current.scrollHeight > wrapperRef.current.clientHeight;
  };

  const scrollOpacityReaction = () => {
    if (checkIsTextBoxLong()) {
      if (contentRef.current.scrollTop === contentRef.current.offsetTop) {
        setIsOpacityClass("text-box-long--top");
      } else if (
        contentRef.current.scrollHeight - contentRef.current.scrollTop ===
        wrapperRef.current.clientHeight
      ) {
        setIsOpacityClass("text-box-long--bottom");
      } else {
        setIsOpacityClass("text-box-long--center");
      }
    } else {
      setIsOpacityClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", scrollOpacityReaction);
    scrollOpacityReaction();

    return () => {
      window.removeEventListener("resize", scrollOpacityReaction);
    };
  }, []);

  return (
    <StyledAboutSection>
      {/* <StyledTitleTemplate theme={themes[isTheme]}>
        <SectionTitle>{t("views.about.title")}</SectionTitle>
      </StyledTitleTemplate> */}

      <StyledWrapper ref={wrapperRef}>
        <StyledContentWrapper
          ref={contentRef}
          className={isOpacityClass}
          onScroll={scrollOpacityReaction}
        >
          <StyledTitleTemplate theme={themes[isTheme]}>
            <SectionTitle>{t("views.about.title")}</SectionTitle>
          </StyledTitleTemplate>
          <StyledArticleContainer>
            <AboutContentOne />
          </StyledArticleContainer>
          <StyledArticleContainer>
            <AboutContentTwo />
          </StyledArticleContainer>
          <StyledArticleContainer>
            <AboutContentThree />
          </StyledArticleContainer>
        </StyledContentWrapper>
      </StyledWrapper>
    </StyledAboutSection>
  );
};

export default AboutSection;
