import { useState, useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import { DecoratorsClass } from "../class/decorators.class";
import styled from "styled-components";
import LightDecortor from "../elems/LightDecorator";
import BioDecorator from "../elems/BioDecorator";
// import TechDecortor from "../elems/TechDecorator";

const StyledParallax = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: ${(props) => props.theme.gradientBackground};
  /* background: radial-gradient(ellipse at top, #1d3549, var(--color-bg-dark));
  background: radial-gradient(
    ellipse at top,
    #1d3549,
    ${(props) => props.theme.colorBackground}
  ); */

  /* background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%); */
  /* background: linear-gradient(to bottom, #1d3549, var(--color-bg-dark)); */
  /* background: radial-gradient(ellipse at top, #1d3549, var(--color-bg-dark)); */
`;

const Parallax = () => {
  // const [lightsArray] = useState(Array(60).fill({}));
  const [bioArray] = useState(Array(20).fill({ bio: new DecoratorsClass() }));
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);

  return (
    <StyledParallax theme={$tc.setContrastTheme(isContrast, isTheme)}>
      {/* <TechDecortor posLeft={"0"} posTop={"50%"} />
      <TechDecortor posRight={"0"} posTop={"50%"} /> */}
      {/* {lightsArray.map((obj, index) => (
        <LightDecortor key={index} index={index} />
      ))} */}
      {/* {bioArray.map((obj, index) => (
        <BioDecorator key={index} index={index} decor={obj.bio} />
      ))} */}
    </StyledParallax>
  );
};

export default Parallax;

// <a href="https://www.freepik.com/free-photo/abstract-luxury-gradient-blue-background-smooth-dark-blue-with-black-vignette-studio-banner_15873388.htm#query=future%20background&position=43&from_view=search&track=sph">Image by benzoix</a> on Freepik
