import styled from "styled-components";

export const StyledSectionTemplate = styled.section`
  position: static;
  /* background-color: var(--color-bg-dark); */
  display: flex;
  font-family: var(--text-title);
  margin: 0 auto;
  padding: 20px;
  padding-top: calc(70px + 5vh);
  padding-bottom: 70px;
  flex-direction: column;

  animation: setSectionVisibilityOn 0.8s forwards;

  @media (min-width: 992px) {
    padding-left: 100px;
    padding-right: 100px;
    height: calc(100vh);
    max-width: 1200px;
  }

  @keyframes setSectionVisibilityOn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .off & {
    display: none;
  }
`;
