import React, { createContext } from "react";
import { outlineMixin } from "../mixins/a11y";

export const globalTheme = {
  light: "light",
  dark: "dark",
};

export const globalContrast = {
  on: true,
  off: false,
};

export const ThemeContext = createContext({
  isTheme: globalTheme.light,
  setTheme: (theme) => {},
});

export const ContrastContext = createContext({
  isContrast: globalContrast.off,
  setContrast: (theme) => {},
});

export const themes = {
  light: {
    colorPrimary: "#007fff",
    colorSecondary: "rgba(0, 127, 255, 0.5)",
    colorDanger: "#0047AB",
    colorTitle: "#000000",
    colorBackground: "#ffffff",
    gradientBackground: "radial-gradient(ellipse at top, #ffffff, #d1d6da)",
    shadowTitle: "none",
    outline: outlineMixin({ color: "#0047AB" }),
    colorBackgroundFocus: "red",
    colorFocus: "red",
    a11yTextDecoration: "red",
    iconDots: "icons/light/dots-transparent.svg",
    iconDotsHover: "icons/light/dots-transparent--hover.svg",
    iconMoonAndSun: "icons/light/moon-transparent.svg",
    iconMoonAndSunHover: "icons/light/moon-transparent--hover.svg",
    iconContrast: "icons/light/contrast-transparent.svg",
    iconContrastHover: "icons/light/contrast-transparent--hover.svg",
  },
  dark: {
    colorPrimary: "#61dafb",
    colorSecondary: "rgba(109, 223, 255, 0.5)",
    colorDanger: "#ff8c00",
    colorTitle: "#ffffff",
    colorBackground: "#070d12",
    gradientBackground: "radial-gradient(ellipse at top, #1d3549, #070d12)",
    shadowTitle: "1px 1px 5px rgba(0, 0, 0, 0.5)",
    outline: outlineMixin({ color: "#ff8c00" }),
    colorBackgroundFocus: "red",
    colorFocus: "red",
    a11yTextDecoration: "red",
    iconDots: "icons/dark/dots-transparent.svg",
    iconDotsHover: "icons/dark/dots-transparent--hover.svg",
    iconMoonAndSun: "icons/dark/sun-transparent.svg",
    iconMoonAndSunHover: "icons/dark/sun-transparent--hover.svg",
    iconContrast: "icons/dark/contrast-transparent.svg",
    iconContrastHover: "icons/dark/contrast-transparent--hover.svg",
  },
};

export const defaultTheme = {
  light: {
    colorPrimary: "#007fff",
    colorSecondary: "rgba(0, 127, 255, 0.5)",
    colorDanger: "#DA70D6",
    colorTitle: "#000000",
    colorText: "#111111",
    colorBackground: "#ffffff",
    colorBackgroundHover: "rgba(0, 127, 255, 0.9)",
    colorHover: "#ffffff",
    gradientBackground: "radial-gradient(ellipse at top, #ffffff, #d1d6da)",
    shadowTitle: "none",
    outline: outlineMixin({ color: "rgba(0, 127, 255, 0.5)" }),
    colorBackgroundFocus: "rgba(0, 127, 255, 0.5)",
    colorFocus: "#000000",
    a11yTextDecoration: "none",
    iconDots: "icons/light/dots-transparent.svg",
    iconDotsHover: "icons/light/dots-transparent--hover.svg",
    iconMoonAndSun: "icons/light/moon-transparent.svg",
    iconMoonAndSunHover: "icons/light/moon-transparent--hover.svg",
    iconContrast: "icons/light/contrast-transparent.svg",
    iconContrastHover: "icons/light/contrast-transparent--hover.svg",
  },
  dark: {
    colorPrimary: "#61dafb",
    colorSecondary: "rgba(109, 223, 255, 0.5)",
    colorDanger: "#ff8c00",
    colorTitle: "#ffffff",
    colorText: "#eeeeee",
    colorBackground: "#070d12",
    colorBackgroundHover: "rgba(109, 223, 255, 0.9)",
    colorHover: "#070d12",
    gradientBackground: "radial-gradient(ellipse at top, #1d3549, #070d12)",
    shadowTitle: "1px 1px 5px rgba(0, 0, 0, 0.5)",
    outline: outlineMixin({ color: "rgba(109, 223, 255, 0.9)" }),
    colorBackgroundFocus: "rgba(109, 223, 255, 0.9)",
    colorFocus: "#000000",
    a11yTextDecoration: "none",
    iconDots: "icons/dark/dots-transparent.svg",
    iconDotsHover: "icons/dark/dots-transparent--hover.svg",
    iconMoonAndSun: "icons/dark/sun-transparent.svg",
    iconMoonAndSunHover: "icons/dark/sun-transparent--hover.svg",
    iconContrast: "icons/dark/contrast-transparent.svg",
    iconContrastHover: "icons/dark/contrast-transparent--hover.svg",
  },
};

export const contrastTheme = {
  light: {
    colorPrimary: "#041E42",
    colorSecondary: "#041E42",
    colorDanger: "red",
    colorTitle: "#000000",
    colorText: "#000000",
    colorBackground: "#ffffff",
    colorBackgroundHover: "#041E42",
    colorHover: "#ffffff",
    gradientBackground: "#ffffff",
    shadowTitle: "none",
    outline: outlineMixin({ color: "#041E42" }),
    colorBackgroundFocus: "#041E42",
    colorFocus: "#ffffff",
    a11yTextDecoration: "underline",
    iconDots: "icons/contrast-light/dots-transparent.svg",
    iconDotsHover: "icons/contrast-light/dots-transparent--hover.svg",
    iconMoonAndSun: "icons/contrast-light/moon-transparent.svg",
    iconMoonAndSunHover: "icons/contrast-light/moon-transparent--hover.svg",
    iconContrast: "icons/contrast-light/contrast-transparent.svg",
    iconContrastHover: "icons/contrast-light/contrast-transparent--hover.svg",
  },
  dark: {
    colorPrimary: "#61dafb",
    colorSecondary: "#61dafb",
    colorDanger: "#ff8c00",
    colorTitle: "#ffffff",
    colorText: "#ffffff",
    colorBackground: "#000000",
    colorBackgroundHover: "#61dafb",
    colorHover: "#000000",
    gradientBackground: "#000000",
    shadowTitle: "none",
    outline: outlineMixin({ color: "#61dafb" }),
    colorBackgroundFocus: "#61dafb",
    colorFocus: "#000000",
    a11yTextDecoration: "underline",
    iconDots: "icons/contrast-dark/dots-transparent.svg",
    iconDotsHover: "icons/contrast-dark/dots-transparent--hover.svg",
    iconMoonAndSun: "icons/contrast-dark/sun-transparent.svg",
    iconMoonAndSunHover: "icons/contrast-dark/sun-transparent--hover.svg",
    iconContrast: "icons/contrast-dark/contrast-transparent.svg",
    iconContrastHover: "icons/contrast-dark/contrast-transparent--hover.svg",
  },
};
