import { useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import styled from "styled-components";
import { StyledGlassTemplate } from "../templates/StyledGlassTemplate";

const StyledHex = styled.svg`
  position: absolute;
  background-color: transparent;
  stroke: lightgray;
  stroke-width: 2;
  fill: transparent;
  transition: 0.2s;
  stroke: ${(props) => props.theme.colorPrimary};
  stroke-linecap: square;

  /* background-color: orangered; */
  border: inset solid 1px black;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0 49%);
  ${StyledGlassTemplate}

  > polygon:nth-of-type(2) {
    stroke-width: 1;
    transition: 0.2s;
    stroke: ${(props) => props.theme.colorSecondary};
  }

  /* .dark-theme & {
    stroke: var(--color-primary-dark);

    > polygon:nth-of-type(2) {
      stroke: var(--color-secondary-dark);
    }
  } */
`;

const Hex = ({ small }) => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);

  return (
    <StyledHex
      small={small}
      xmlns="http://www.w3.org/2000/svg"
      width={small ? "60" : "100"}
      height={small ? "48" : "80"}
      theme={$tc.setContrastTheme(isContrast, isTheme)}
    >
      <defs>
        <clipPath id="hex">
          {!small && (
            <polygon points="1,40 26,1 74,1 99,40 74,79 26,79"></polygon>
          )}
          {/* {!small && (
            <polygon points="6.5,40 29,5 71,5 94,40 71,75 29,75"></polygon>
          )} */}
          {small && (
            <polygon points="1,23 14,1 46,1 59,23 46,47 14,47"></polygon>
          )}
          {/* {small && (
            <polygon points="6,23 16,5 44,5 54,23 44,43 16,43"></polygon>
          )} */}
        </clipPath>
      </defs>
      {!small && <polygon points="1,40 26,1 74,1 99,40 74,79 26,79"></polygon>}
      {!small && (
        <polygon points="6.5,40 29,5 71,5 94,40 71,75 29,75"></polygon>
      )}
      {small && <polygon points="1,23 15,1 45,1 59,23 45,47 15,47"></polygon>}
      {small && <polygon points="6,23 17,5 43,5 54,23 43,43 17,43"></polygon>}
    </StyledHex>
  );
};

export default Hex;
