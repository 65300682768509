import { useState, useEffect, useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledInputField = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  /* width: 300px; */

  @media (min-width: 992px) {
    margin: 10px;
  }

  > span {
    margin-top: 3px;
    font-size: 0.7rem;
    color: ${(props) => props.theme.colorDanger};
  }
`;

const StyledLabel = styled.label`
  font-family: var(text-title);
  font-size: 0.8rem;
  color: ${(props) => props.theme.colorPrimary};
  margin-bottom: 5px;
`;

const StyledInput = styled.input`
  background-color: rgba(255, 255, 255, 0.05);
  border: solid 4px ${(props) => props.theme.colorSecondary};
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1rem;
  color: ${(props) => props.theme.colorTitle};
  letter-spacing: 0.2px;
  backdrop-filter: blur(10px);

  &:focus-visible {
    ${(props) => props.theme.outline};
  }
`;

const StyledTextarea = styled.textarea`
  background-color: rgba(255, 255, 255, 0.05);
  border: solid 4px ${(props) => props.theme.colorSecondary};
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1rem;
  color: ${(props) => props.theme.colorTitle};
  letter-spacing: 0.2px;
  backdrop-filter: blur(10px);

  &:focus-visible {
    ${(props) => props.theme.outline};
  }
`;

const InputElem = ({ label, type, action, empty, validation, valid }) => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);
  const [isValue, setValue] = useState("");
  // const [isValid, setValid] = useState(true);
  const { t } = useTranslation();

  const setAction = (e) => {
    setValue(e.target.value);
  };

  const checkFormat = (str) => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const found = str.match(regex);
    // found !== null ? console.log("true") : console.log("false");
    found !== null ? validation(true) : validation(false);
  };

  useEffect(() => {
    action(isValue);
    // type === "email" && console.log(isValue.length);
    type === "email" && checkFormat(isValue);
  }, [isValue]);

  return (
    <StyledInputField theme={$tc.setContrastTheme(isContrast, isTheme)}>
      <StyledLabel
        htmlFor={label}
        theme={$tc.setContrastTheme(isContrast, isTheme)}
      >
        {label}
      </StyledLabel>
      {type !== "textarea" && (
        <StyledInput
          id={label}
          name={label}
          type={type}
          value={isValue}
          onChange={setAction}
          required
          theme={$tc.setContrastTheme(isContrast, isTheme)}
        />
      )}
      {type === "textarea" && (
        <StyledTextarea
          id={label}
          name={label}
          type={type}
          value={isValue}
          onChange={setAction}
          rows="5"
          // cols="30"
          required
          theme={$tc.setContrastTheme(isContrast, isTheme)}
        />
      )}
      {empty && !isValue && (
        <span>* {t("views.contact.messages.fieldRequired")}</span>
      )}
      {type === "email" && isValue.length > 0 && !valid && (
        <span>* {t("views.contact.messages.emailInvalid")}</span>
      )}
    </StyledInputField>
  );
};

export default InputElem;
