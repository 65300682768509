export default {
  views: {
    initial: {
      hello: "Witaj na mojej stronie zawodowej!",
      question: "Chciałbyś wzmocnić swój zespół?",
    },
    welcome: {
      hello: "Cześć, jestem",
    },
    about: {
      title: "O mnie",
      content: {
        one: {
          title: "Obecnie",
          text: {
            p1: "Od marca 2021 roku pracuję na pełny etat jako front-end developer w <0>Autopay SA</0> (wcześniej Blue Media SA), firmie dostarczającej rozwiązania do obsługi transakcji elektronicznych.",
            p2: "Ponadto jestem członkiem społeczności <0>HackerSpace Trójmiasto</0> i angazuję się w pozazawodowe projekty z obszaru IT. W czerwcu 2022 roku podczas 2. edycji hackathonu technologicznego i ekologicznego Hack4Change w Gdańsku nasz zespół otrzymał 2. nagrodę za projekt aplikacji wspierającej dzieci z wyzwaniami (<0>Żeton</0>).",
            p3: "Prowadziłem też kursy frontendowe, m.in. w ramach HackerSpace Trójmisto oraz dla InfoShare Academy. Brałem udział w 255. i 256. <0>Tygodniu Programisty</0>, gdzie prowadziłem warsztaty w JS i nowoczesnego stylowania. Czterokrotnie w ramach projektu <1>Meet and Code</1> realizowałem warsztaty frontendowe z podstaw SASS/SCSS, Tailwinda i dostepności cyfrowej.",
            p4: "Udzielam się także w gdańskim forum dostępności: A11y - Accessibility - Dostępność, co wiąże się z podnoszeniem kwalifikacji w obszarze dostępności cyfrowej. W 2021 roku ukończyłem szkolenie w tym obszarze prowadzone przez <1>Fundację Widzialni</1>. W maju 2023 brałam udział w warsztatach z dostępności w <2>Gdańskiej Galerii Zmysłów</2>.",
          },
          images: {
            fig1: "Spotkanie 1. forum dostępności w Gdańsku (autor: Marcin Klinikowski)",
            fig2: "Spotkanie 2. forum dostępności w Gdańsku (autor: Marcin Klinikowski)",
            fig3: "Moja prezentacja podczas 2. forum dostępności w Gdańsku (autor: Marcin Klinikowski)",
            fig4: "Certyfikact ukończenia kursu dostępności",
            fig5: "Rozdanie nagród na Hack4change",
            fig6: "Warsztaty w ramach projektu Meet and Code",
            alt1: "Grupa ludzi pozujących razem do zdjęcia.",
            alt2: "Grupa ludzi pozujących razem do zdjęcia.",
            alt3: "Mężczyzna prezentujący slajdy.",
            alt4: "Grafika przedstawiająca zaświadczenie o ukończeniu kursu dostępności.",
            alt5: "Kilka osób siedzących przy stole z komputerami, w tle postać prowadzącego.",
          },
        },
        two: {
          title: "Moje początki w IT",
          text: {
            p1: "Zacząłem zmieniać swoją karierę jako programista front-end w 2018 roku. Początkowo uczyłem się podstaw frontendu samodzielnie i z kursów internetowych.",
            p2: "W 2019 roku ukończyłem 45-godzinny kurs 'UX designer' w InfoShare Academy, prowadzony przez Karolinę Trokę (PGS Software S.A.), co pozwoliło mi lepiej poznać proces projektowania doświadczeń użytkownika w produktach cyfrowych.",
            p3: "W marcu 2020 ukończyłem 72-godzinny kurs 'JavaScript + React od podstaw' (Fundacja Code:me), prowadzony przez Marcina Kałużnego (Wirtualna Polska). W 2020 brałem również udział w warsztatach UX designu dla projektu aplikacji 'Żeton', prowadzonych przez Weronikę Spaleniaka (Digital Toucan: Panorama & JQL Search Extensions for Jira).",
            p4: "W latach 2019-2021 jako freelancer frontend developer wykonywałem projekty stron internetowych, m.in. we współpracy z <0>Fundacją Between-Pomiędzy</0>.",
          },
          images: {
            fig1: "Certyfikat z kursu projektowania UX",
            alt1: "Grafika przedstawiająca zaświadczenie o ukończeniu kursu UX Desinger.",
            fig2: "Ukończenie kursu UX designe",
            alt2: "Grupa osób trzymających dyplomy ukończenia kursu.",
            fig3: "Certyfikat z kursu JavaScriptu z Reactem",
            alt3: "Grafika przedstawiająca zaświadczenie o ukończeniu kursu JavaScript + React od podstaw.",
            fig4: "Szkolenie programistyczne Fundacji Code:me",
            alt4: "Grupa programistów z laptopami.",
          },
        },
        three: {
          title: "Wcześniejsze doświadczenie",
          text: {
            p1: "Jeszcze przed decyzją o przebranżowieniu miałem pewne doświadczenie w nieprofesjonalnym projektowaniu stron internetowych opartych na CMS i grafice cyfrowej: okładki książek i czasopism, grafika, logo, ikony.",
            p2: "Mam duże doświadczenie w pracy naukowej i dydaktycznej. Przez 11 lat pracowałem jako adiunkt w <0>Akademii Pomorskiej w Słupsku</0>. Wcześniej byłem też nauczycielem w liceum.",
          },
        },
      },
    },
    stack: {
      title: "Mój stack",
      subtitles: {
        front: "Technologie frontendowe",
        back: "Technologie backendowe",
        design: "Projektowanie",
        test: "Narzędzia testerskie",
        dev: "Narzędzia developerskie",
      },
    },
    resume: {
      title: "CV",
      labels: {
        english: "Angielski",
        polish: "Polski",
        subtitle: "pobierz moje CV LUB odwiedź moje profile",
      },
      buttons: {
        openPdf: "Otwórz dokument pdf w nowym oknie",
      },
    },
    contact: {
      title: "Kontakt",
      labels: {
        email: "Twój email",
        message: "Wiadomość",
      },
      buttons: {
        submit: "Wyślij",
      },
      messages: {
        success: "Sukces!",
        succesText: "Wiadomość została wysłana.",
        failed: "Błąd!",
        failedText: "Nie udało się wysłać wiadomości. Spróbuj ponownie.",
        fieldRequired: "Pole jest wymagane!",
        emailInvalid: "Wprowadź poprawny adres e-mail!",
      },
    },
  },
  common: {
    on: "Tak",
    off: "Wychodzę",
    author: "Paweł Nieczuja-Ostrowski",
    profession: "Frontend Developer",
    start: "Start",
    about: "O mnie",
    stack: "Stack",
    resume: "CV",
    contact: "Kontakt",
    see: "zobacz",
    a11y: {
      handlePower: "Włącz widok strony",
      handleCockpit: {
        on: "Pokaż główne menu",
        off: "Ukryj główne menu",
      },
      handleSettings: {
        on: "Rozwiń menu ustawień",
        off: "Zwiń menu ustawiń",
      },
      handleTheme: {
        dark: "Włącz ciemny schemat kolorów",
        light: "Włącz jasny schemat kolorów",
      },
      handleLanguage: {
        en: "Włącz angielską wersję językową strony",
        pl: "Włącz polską wersję językową strony",
      },
      handleContrast: {
        on: "Włącz wysoki kontrast na stronie",
        off: "Wyłącz wysoki kontrast na stronie",
      },
      contact: "Przejdź do sekcji kontaktu",
      submit: "Wyślij wiadomość",
      submitReaction: "Potwierdź i wróć do formularza",
      start: "Przejdź do sekcji startowej",
      about: "Przejdź do sekcji prezentującej sylwetkę autora",
      stack: "Przejdź do sekcji prezentującej umiejętności autora",
      resume: "Przejdź do sekcji zawierającej CV autora",
      openBlank: "Otwórz w nowym oknie i przejdź do strony {{ name }}",
    },
  },
};
