import { useContext } from "react";
import styled from "styled-components";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";

const StyledFigure = styled.figure`
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex: 1;

  @media (min-width: 992px) {
    max-width: 50%;
    min-width: 49%;
  }

  img {
    width: 100%;
    border-radius: 5px;

    @media (min-width: 992px) {
      font-size: 2rem;
      /* width: 50%; */
      width: 98%;
      object-fit: cover;
      aspect-ratio: 16 / 10;
      /* height: 280px; */
    }
  }

  figcaption {
    font-size: 0.8rem;
    color: ${(props) => props.theme.colorText};
  }
`;

const ImageFigure = ({ src, alt, caption }) => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);

  return (
    <StyledFigure theme={$tc.setContrastTheme(isContrast, isTheme)}>
      <img src={src} alt={alt} />
      <figcaption>{caption}</figcaption>
    </StyledFigure>
  );
};

export default ImageFigure;
