import React, { useContext } from "react";
import styled from "styled-components";
import { CockpitContext, globalCockpit } from "../context/cockpit-context";
import ButtonHex from "./ButtonHex";
import { useTranslation } from "react-i18next";

const StyledHandleCockpitWrapper = styled.div`
  position: fixed;
  top: 10px;
  /* left: 50%; */
  left: 10px;
  /* transform: translateX(-50%); */
  z-index: 100;
  cursor: pointer;
  transition: 1s;

  .active button {
    text-decoration: none;
    text-decoration-thickness: 0;
  }

  .off & {
    display: none;
  }

  .cockpit & {
    top: 10px;
  }

  .cockpitName {
    margin-top: 40px;
    font-size: 1.5rem;
  }

  @media (min-width: 992px) {
    /* margin: 20px 10px 10px auto; */
  }

  .dark-theme & {
    /* border-color: var(--color-primary-dark);
    color: var(--color-primary-dark); */
  }

  &:hover {
    /* background-color: var(--color-primary-light);
    color: var(--color-title-dark); */

    .dark-theme & {
      /* background-color: var(--color-secondary-dark);
      color: var(--color-title-dark); */
    }
  }

  .theme-mark {
    font-size: 1.5rem;
    line-height: 0.9;
  }
`;

const StyledHandleCockpitButton = styled.button`
  background-color: transparent;
  border: none;
  outline: 0;
  margin: auto;
  padding: 10px 10px 15px;
  cursor: pointer;
  /* transform: rotateZ(90deg); */
  font-size: 1.8rem;
  line-height: 1;
  color: ${(props) => props.theme.colorPrimary};
  z-index: 0;

  &:hover {
    color: ${(props) => props.theme.colorSecondary};
  }

  .cockpit & {
    /* transform: rotateZ(-90deg); */
  }

  &:focus {
    ${(props) => props.theme.outline};
  }
`;

const HandleCockpitButton = () => {
  const { t } = useTranslation();
  const cockpit = useContext(CockpitContext);

  const handleCockpit = () => {
    cockpit.setCockpit(
      cockpit.isCockpit ? globalCockpit.hide : globalCockpit.show
    );
  };

  return (
    <StyledHandleCockpitWrapper>
      {/* <StyledHandleCockpitButton
        onClick={handleCockpit}
        theme={themes[isTheme]}
      >
        <span>&#9776;</span>
      </StyledHandleCockpitButton> */}
      <ButtonHex
        small
        light
        action={() => handleCockpit()}
        active={cockpit.isCockpit}
        title={
          cockpit.isCockpit
            ? t("common.a11y.handleCockpit.off")
            : t("common.a11y.handleCockpit.on")
        }
      >
        <span className="theme-mark">&#9776;</span>
      </ButtonHex>
      {/* <ButtonHex action={handleCockpit}>
        <span className="cockpitName">&#9776;</span>
      </ButtonHex> */}
    </StyledHandleCockpitWrapper>
  );
};

export default HandleCockpitButton;
