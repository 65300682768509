import { useContext } from "react";
import styled from "styled-components";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledLinkBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: dotted 2px ${(props) => props.theme.colorPrimary};
  transition: 0.2s;
  /* background-color: pink; */
  z-index: 1;

  margin: ${(props) => (props.small ? "15px 15px 10px" : "20px 20px 30px")};
  height: ${(props) => (props.small ? "70px" : "90px")};
  width: ${(props) => (props.small ? "70px" : "90px")};

  &:hover {
    background-color: ${(props) => props.theme.colorBackgroundHover};

    > a span {
      color: ${(props) => props.theme.colorHover};
    }

    > a svg {
      height: 2rem;

      > path {
        fill: ${(props) => props.theme.colorHover};
      }
    }
  }
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.colorTitle};
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: normal;
  outline: none;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > svg {
    height: 2rem;

    > path {
      fill: ${(props) => props.theme.colorTitle};
    }
  }

  > span {
    font-size: 0.8rem;
    margin: 5px 0 0;
    color: ${(props) => props.theme.colorPrimary};
  }

  &:focus-visible {
    background-color: ${(props) => props.theme.colorBackgroundFocus};
    color: ${(props) => props.theme.colorFocus};

    > span {
      color: ${(props) => props.theme.colorFocus};
    }

    > svg {
      > path {
        fill: ${(props) => props.theme.colorFocus};
      }
    }

    .link-box && {
      ${(props) => props.theme.outline};
    }
  }
`;

const ResumeLink = ({ title, link, icon, text, small }) => {
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);
  return (
    <StyledLinkBox
      className="link-box"
      theme={$tc.setContrastTheme(isContrast, isTheme)}
      small={small}
    >
      <StyledLink
        href={link}
        title={title}
        target="_blank"
        theme={$tc.setContrastTheme(isContrast, isTheme)}
        small={small}
      >
        <FontAwesomeIcon icon={icon} />
        {text && <span>{text}</span>}
      </StyledLink>
    </StyledLinkBox>
  );
};

export default ResumeLink;
