import { useContext } from "react";
import { ThemeContext, ContrastContext } from "../context/theme-context";
import { ToolsClass as $tc } from "../class/tools.class";
import styled from "styled-components";
import SectionTitle from "../elems/SectionTitle";
import PageSubtitle from "../elems/PageSubtitle";
import { StyledSectionTemplate } from "../templates/StyledSectionTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile as faFile } from "@fortawesome/free-regular-svg-icons";
import { StyledTitleTemplate } from "../templates/StyledTitleTemplate";
import ContactSocials from "../modules/ContactSocials";
import { useTranslation } from "react-i18next";
import ResumeLink from "../elems/ResumeLink";

const StyledResumeSection = styled(StyledSectionTemplate)``;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledPdfButtonBox = styled(ResumeLink)`
  margin: 20px 20px 30px;
  height: 90px;
  width: 90px;
`;

const ResumeSection = () => {
  const { t } = useTranslation();
  const { isTheme } = useContext(ThemeContext);
  const { isContrast } = useContext(ContrastContext);

  return (
    <StyledResumeSection>
      <StyledTitleTemplate>
        <SectionTitle>{t("views.resume.title")}</SectionTitle>
      </StyledTitleTemplate>

      <StyledWrapper>
        <StyledPdfButtonBox
          title={t("views.resume.buttons.openPdf")}
          link={"docs/CV_Nieczuja_2023_11_EN.pdf"}
          icon={faFile}
          text={t("views.resume.labels.english")}
        />
        <StyledPdfButtonBox
          title={t("views.resume.buttons.openPdf")}
          link={"docs/CV_Nieczuja_2023_11_PL.pdf"}
          icon={faFile}
          text={t("views.resume.labels.polish")}
        />
      </StyledWrapper>

      <StyledTitleTemplate>
        <PageSubtitle>{t("views.resume.labels.subtitle")}</PageSubtitle>
      </StyledTitleTemplate>
      <StyledWrapper>
        <ContactSocials />
      </StyledWrapper>
    </StyledResumeSection>
  );
};

export default ResumeSection;
