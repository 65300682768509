import styled from "styled-components";

export const StyledTitleTemplate = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  > p {
    font-size: 1.2rem;
    /* margin: 10px 0; */
    color: ${(props) => props.theme.colorTitle};

    /* .dark-theme & {
      color: var(--color-title-dark);
    } */
  }
`;
