export class DecoratorsClass {
  // static renderPosTop() {
  //   // const height = window.innerHeight;
  //   // const posTop = Math.floor(Math.random() * height);
  //   const posTop = Math.floor(Math.random() * 100);
  //   return posTop;
  // }

  renderPosTop() {
    const posTop = Math.floor(Math.random() * 120) - 10;
    return posTop + "%";
  }

  renderPosLeft() {
    const posLeft = Math.floor(Math.random() * 120) - 10;
    return posLeft + "%";
  }

  renderRadius() {
    const radius = Math.floor(Math.random() * 10) + 15;
    return radius + "px";
  }

  renderOpacity() {
    const opacity = Math.ceil(Math.random() * 5) / 10;
    return opacity + "";
  }

  renderTimeFunctioning() {
    const time = Math.ceil(Math.random() * 20) + 15;
    return time + "s";
  }

  // static setObjectToLightElem() {
  //   const obj = {
  //     posTop: this.renderPosTop(),
  //     posLeft: this.renderPosLeft(),
  //     radius: this.renderRadius(),
  //     opacity: this.renderOpacity(),
  //   };
  //   return obj;
  // }
}
