import React from "react";
import styled from "styled-components";
import SectionTitle from "../elems/SectionTitle";
import { StyledSectionTemplate } from "../templates/StyledSectionTemplate";
import { StyledTitleTemplate } from "../templates/StyledTitleTemplate";
import ContactForm from "../modules/ContactForm";
import ContactSocials from "../modules/ContactSocials";
import BoxSwitcher from "../modules/BoxSwitcher";
import { useTranslation } from "react-i18next";

const StyledContactSection = styled(StyledSectionTemplate)``;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* margin: 10px;
  padding: 10px; */
  /* width: 100%; */
  height: 100%;
`;

const StyledArticleContainer = styled.article`
  display: flex;
  flex-direction: column;
`;

const StyledFormContainer = styled(StyledArticleContainer)`
  justify-content: space-around;
`;

const StyledButtonsContainer = styled(StyledArticleContainer)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto); */
`;

const ContactSection = () => {
  const { t } = useTranslation();
  return (
    <StyledContactSection>
      <StyledTitleTemplate>
        <SectionTitle>{t("views.contact.title")}</SectionTitle>
      </StyledTitleTemplate>
      <StyledWrapper>
        <ContactForm />
        {/* <BoxSwitcher>
          <StyledFormContainer>
          </StyledFormContainer>
          <StyledButtonsContainer>
            <ContactSocials />
          </StyledButtonsContainer>
        </BoxSwitcher> */}
      </StyledWrapper>
    </StyledContactSection>
  );
};

export default ContactSection;
