import styled from "styled-components";

export const StyledButtonsCentralWrapper = styled.nav`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 300px; */
  margin-top: 5px;
  padding-bottom: 15px;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > span {
      color: red;
      min-width: 60px;
      height: 1px;
      color: var(--color-primary-light);
      border-bottom: dotted 2px var(--color-primary-light);

      .dark-theme & {
        color: var(--color-primary-dark);
        border-color: var(--color-secondary-dark);
      }
    }
  }
`;

export const StyledButtonCentral = styled.button`
  background-color: transparent;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-family: var(--text-title);
  font-size: 1rem;
  z-index: 1;

  color: var(--color-primary-light);
  border: dotted 2px var(--color-primary-light);

  .dark-theme & {
    color: var(--color-primary-dark);
    border-color: var(--color-secondary-dark);
  }

  &:hover {
    color: var(--color-title-light);

    .dark-theme & {
      color: var(--color-title-dark);
    }
  }

  &.active {
    color: var(--color-title-light);
    border: solid 2px var(--color-primary-light);
    font-size: 1.4rem;
    width: 45px;
    height: 45px;

    .dark-theme & {
      color: var(--color-title-dark);
      border-color: var(--color-secondary-dark);
    }
  }
`;
